import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TEXTS} from '../../../common/texts';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs/Subscription';
import {CoefService} from '../../../services/coef.service';
import {ScrollService} from '../../../services/scroll.service';
import {PageService} from '../../../services/page.service';
import {ScrollUpDownService} from '../../../services/scrollUpDown.service';

@Component({
  selector: 'app-home-five',
  templateUrl: './home-five.component.html',
  styleUrls: ['./home-five.component.scss'],
  animations: [
    trigger('scrolledState', [
      state('hidden', style({
        width: '45%',
        paddingBottom: '16rem',
        paddingTop: '16rem'
      })),
      state('shown', style({
        width: '45%',
        paddingBottom: '16rem',
        paddingTop: '16rem'
      })),
      transition('hidden => shown', animate('0.3s ease-out'))
    ])
  ]
})
export class HomeFiveComponent implements OnInit, OnDestroy, AfterViewInit {
  private streams$: Subscription[] = [];

  readonly TEXT = TEXTS;

  animationState = 'hidden';
  private scrollPosition: number;
  private coef: number;
  @ViewChild('walla') walla: ElementRef;
  isMobile: boolean;

  constructor(private coefService: CoefService,
              private scrollService: ScrollService,
              private pageService: PageService ,
              private scrollUpDownService: ScrollUpDownService) {
    this.isMobile = this.coefService.getDeviceType();
  }


  goWalla() {
    this.pageService.goWalla();
  }


  ngOnInit() {
    this.coef = this.coefService.getCoef();
    this.streams$.push(this.coefService.coefHasChanged.subscribe(
      (newCoef: number) => {
        this.coef = newCoef;
      }
    ));

    this.streams$.push(this.scrollService.scrollPositionHasChanged.subscribe(
      (newScrollPosition: number) => {
        this.scrollPosition = newScrollPosition;
        if (this.scrollPosition > 4400 * this.coef) {
          this.animationState = 'shown';
        }
      }
    ));
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      if(!this.scrollUpDownService.homePageProjectScroll){
        window.scrollTo(this.walla.nativeElement.offsetTop, 0);
      }
    } , 100);
    this.scrollService.getScrollPosition().then(e => {
      this.scrollPosition = +e;
      if (this.scrollPosition > 4400 * this.coef) {
        this.animationState = 'shown';
      }
    });
  }

  ngOnDestroy() {
    for (const stream$ of this.streams$) {
      stream$.unsubscribe();
    }
  }

}
