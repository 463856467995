export const TEXTS = {
  myText: 'text text text',
  SefiBogin: 'Sefi Bogin',
  UXDesign: 'ux design & creative',
  title_sefi_home_floor: 'בדרך שלי',
  btnTextProjectHome: 'צפה בפרויקטים',
  description_sefi_home_floor: ' הצלחתי, נפלתי, התקדמתי אבל תמיד-תמיד שמרתי על העקרונות ותהליך היצירה.\n' +
  'במילה אחת - מוזה.\n',
  description_sefi_home_floorMob: 'למדתי, הצלחתי, נפלתי, התקדמתי אבל תמיד-תמיד שמרתי על העקרונות ותהליך היצירה.\n' +
  'במילה אחת - מוזה.\n',
  btnTextFloorHome: 'קצת עליי',
  textWallaHome: 'מיתוג מחודש לענקית התיירות שהחליטה לשים את הדיגיטל במרכז הפעילות במובייל. תהליך ארוך ומעמיק שבכל כיוון מציג חוויית משתמש מובילה.',
  textTempoHome: 'חברת המשקאות המובילה והותיקה נכנסה לעולם הדיגיטל בעוצמה גדולה עם אתר קריאטיבי עשיר בצבעים, טעמים וחוויות.',
  hashtag: '#מיתוג / #מובייל / #UI# / UX / #מיתוג',
  hashtag2: '#מיתוג / #מובייל / #UI# / UX / #מיתוג /\n' +
  '#פוסט פרודקשיין / #אנימציה',
  textFiatHome: 'איך מקפיצים את גרף המכירות השנתי ביום מכירות אחד?\n' +
  'קמפיין של חודשיים, המבוסס על פילוח מדוייק וקריאייטיב מעולה הביא מאות רוכשים מאושרים ביומיים מכירות.\n',
  textYuvalHome: 'יובל לא התבלבל כלל כאשר הבין את כח ה-Gaming, כערוץ הפצת תכנים וחיזוק מותג.\n' +
  'אפליקציה חינוכית מרהיבה העוזרת בהתמודדות הילדים בפעולות היומיומיות השונות.\n',
  textHuggiesHome: '״מי הזיז את החיתול שלי?״ לשוק הקמעוני נוספו מתחרים נוספים והאגיס נדרשה להשיג יתרון מסחרי. מהאפליקציה לדלת הבית.',
  textSumsungHome: 'סטארטאפ שהגיח לעולם בכדי לעזור לגיל השלישי, ע״י טאבלט וממשק מותאם כמרכז תקשורת רפואית למבוגר, לילדיו ולמוקד בקרה רפואי. התראות ותזכורות לתרופות וטיפולים, שיחות עם רופא וילדיו ולחצן מצוקה.',
  textSefiHome: 'במהלך העשור האחרון עזרתי ליצור שווים וערכים\n' +
  'בהיקף של מאות מיליוני דולרים לחברות ועסקים\n' +
  'בארץ ובעולם.\n',
  textSefiHomeMobile: 'במהלך העשור האחרון עזרתי ליצור שווים וערכים\n' +
  'בהיקף של מאות מיליוני דולרים לחברות\n' +
  'ועסקים בארץ ובעולם.',
  titleBigSefi: '20 שנה',
  titleLitleSefi: 'מעל 500 פרוייקטים',
  btnWalla: 'מה היה לנו?',
  textLoading : 'כמה שניות\n' +
  'והפרויקט יוצא\n' +
  'מהתנור',
  big_menu: {
    who: 'מי? מה? איך?',
    projects: 'פרויקטים',
    history: 'סרטון סיפור חיי',
    contact: 'צור קשר'
  },
  loadmore: 'זה לא הכל..',
  loadmore2: 'טוב חאלאס! ת׳קשרו',
  fiatPageHeadings: {
    desktop: 'desktop',
    mobile: 'mobile',
    case: 'case',
    study: 'study',
    theMini: 'The mini'
  },
  homeNote: {
    firstLine: 'מר <span class="english">Google</span> היקר,',
    mainText: '\n' +
    'בהגיעי לתחתית האתר החדש של עצמי חשבתי על שכבת הקידום ומפת האתר.\n' +
    'אבל ביננו מי באמת כבר משתמש בזה? קיימים כלים אחרים שיישמתי ויתנו מענה למבקרים באתר שלי.\n' +
    '\n' +
    'שמע, 20 שנה אני בונה אתרים ומתחשב בדרישותיך - באמת.\n' +
    'באתר שלי הפעם, אני רוצה להדגיש תמונות ווידאויים על פני טקסט לכן אני פשוט ארשום כאן את מילות המפתח שחשובות לי ואתה תתחשב בי ותעזור לי להגיע ללקוח הבא שלי.\n'+
    '\n' +
    'בכבוד רב,\n' +
    'תודה על שיתוף הפעולה,\n' +
    'ספי בוגין',
    firstPiece: ' מעצב ממשק משתמש',
    secondPiece: ' אפיון מוצר דיגיטלי',
    thirdPiece: 'אפיון חווית משתמש',
    fourthPiece: 'עיצוב ואפיון משחקים',
    fifthPiece: 'עיצוב ויצירת בראנדים',
    sixthPiece: 'מעצב מותגים בדיגיטל',
    seventhPiece: 'מעצב חווית משתמש',
    eightPiece: 'מיתוג ו שפה וזיואלית',
    ninethPiece: 'עיצוב ותכנון אפליקציות',
    tenhPiece: 'עיצוב חווית משתמש <span class="english">UX</span>',
  },

  fiatPageArticleHeading: {
    line1: 'השקת הג׳יפון החדש של חברת פיאט',
    line2: '',
  },
  fiatPageArticleText: {
    line1: 'לחץ במסדרונות הנהלת פיאט ישראל מהשקת ג׳יפון בסיגמנט חדש עקב תחרות במותגי רכב ענקיים השולטים בישראל על ',
    line2: 'הכבישים.',
    line3: ' כאן אני נכנס. בלחץ של זמן, יעדים לא הגיוניים יישמנו אסטרטגיית דיגיטאל שלמה שנבנתה ב-2 שלבים, פרומו והסרת הוילון. ',
    line4: 'הכל התכנס לטקס השקה ויומיים מכירות שהביאו למכירת 200 מכוניות ביומיים.',
    line5: 'למי שלא מצוי, זה נס גלוי.'
  },
  fiatPage: {
    secondFloor: {
      heading: 'השקת הג׳יפון החדש של חברת פיאט',
      text: 'לחץ במסדרונות הנהלת פיאט ישראל מהשקת ג׳יפון בסיגמנט חדש עקב תחרות במותגי רכב ענקיים השולטים בישראל על הכבישים.\n' +
      'כאן אני נכנס. בלחץ של זמן, יעדים לא הגיוניים יישמנו אסטרטגיית דיגיטאל שלמה שנבנתה ב-2 שלבים, פרומו והסרת הוילון. הכל התכנס לטקס השקה ויומיים מכירות שהביאו למכירת 200 מכוניות ביומיים.\n' +
      'למי שלא מצוי, זה נס גלוי.\n'
    },
  },
  wallaPageText1: {
    title: 'מה במוזה שלך?',
    title2: 'מריחים את הקורסון?',
    text: 'גישה של הפוך.. חאלאס עם מנוע חיפוש משעמם ושיגרתי.\n' +
    'דווקא מהכיוון של הדימיון והמצב רוח שתבחר תגיע לחופשה.\n',
    textMobile: 'גישה של הפוך.. חאלאס עם מנוע חיפוש משעמם ושיגרתי.\n' +
    'דווקא מהכיוון של הדימיון והמצב רוח שתבחר תגיע לחופשה.\n',
    text2: 'וידאו, וידאו ועוד וידאו עם נגיעה של חוויית משתמש צעירה וססגונית.\n' +
    'כל השאר זורם טבעי.\n' +
    'עד שתקבלו את הבאגט בחיוב האשראי הבא :)\n',
    textMobile2: 'וידאו, וידאו ועוד וידאו עם נגיעה של חוויית משתמש צעירה וססגונית.\n' +
    'כל השאר זורם טבעי.\n' +
    'עד שתקבלו את הבאגט בחיוב האשראי הבא :)\n',
  },
  huggiesPageText: {
    signature_logo: 'Case Study',
    title1: 'פיצוח קונספט עסקי שלם',
    description1: 'יצירת תהליך עסקי למכירת חיתולים שיגרום לאם ולאב הטריים להוריד את\n' +
    'האפליקציה ע״י קבלת גיפט קארד מחבריהם הקרובים ולהתרגל לצריכת חיתולים און ליין.',
    descriptionMobile1: 'מיתוג מחודש לענקית התיירות שהחליטה לשים את\n' +
    ' שלוש הדיגיטל במרכז הפעילות. תהליך ארוך ומעמיק\n' +
    'ואיך שלא אלך לשם  חוויית המשתמש מובילה.',
  },
  huggiesPageScheme: {
    titleElement1: '1. החדרת האפליקציה',
    titleElement2: '2. אפליקציה להזמנת חיתולים',
    titleElement3: '3. לוגיסטיקה ושילוח',
    titleElement4: '4. החבילה הגיעה',
    titleElement5: '5. שימור הלקוחות',

    textElement1: 'איך גורמים לאמא שעכשיו ילדה\n' +
    'להוריד אפליקציה... שולחים לה\n' +
    'מתנה בצורת Gift Card.\n',
    textElement2: 'אין קהל יעד יותר רלוונטי לגישת\n' +
    'Mobile First מאמא שרק עכשיו\n' +
    'ילדה את התינוקה הראשון.\n',
    textElement3: 'זה תפקידו המסורתי של אבא\n' +
    'הוא ישמח שהסרנו ממנו את\n' +
    ' ״מטלת הסופר פארם״',
    textElementMobile4: 'זה תפקידו המסורתי של אבא הוא ישמח שהסרנו ממנ\n' +
    ' ״מטלת הסופר פארם  את\n',
    textElement5: 'כעת נשאר רק להזכיר\n' +
    'לאמא שתזכיר לאבא שמלאי\n' +
    'החיתולים עומד להסתיים\n' +
    'ומלאי חדש במרחק .שתי\n' +
    'לחיצות בנייד\n',
  },

  huggiesPageSectionDesign: {
    title: 'ככה ימכרו חיתולים ב-2025',
    text: 'אז איך זה שלא נחשפתם לזה, אתם שואלים?\n' +
    'כי ההנהלה חושבת שזה מסוכן להם עסקית (אחרי שהכל נבנה).\n' +
    'לך תבין.. העיקר שילמו.\n',
    textMobile: 'אז איך זה שלא נחשפתם לזה, אתם שואלים?\n' +
    'כי ההנהלה חושבת שזה מסוכן להם עסקית (אחרי שהכל נבנה).\n' +
    'לך תבין.. העיקר שילמו.\n',
  },

  huggiesPageSectionCharacterizationPeople: {
    title1: 'אפיון פרסונות קהל מטרה',
    title2: 'אמהות בשלבי הריון ראשון מתעניינות',
    title3: 'אמהות לאחר לידה ראשונה',
    textLeft: {
      li: 'בדיקות רפואיות כלליות לעובר ולאם',
      li2: 'קורס הכנה ללידה',
      li3: 'צריכת מידע דרך המובייל והדסקטופ, כ-4 שעות ביום',
    },
    textRight: {
      li: 'עוברת משבר פיזי ונפשי כמס׳ שבועות לאחר הלידה',
      li2: 'מתרגשות לאור השינוי החדש',
      li3: 'צריכת מידע במובייל, כ-8 שעות ביום',
    }
  },
  huggiesPageSectionApp: {
    title: 'שלב תכנון חוויית המשתמש UX',
    text: 'תהליך פשוט המוביל בויזארד חוייתי מהנה לרכישת חיתולים. \n' +
    'בהריון עוד לא יצא לי להיות, אבל נראה לי שהתהליך עובד.\n',
  },

  footerPortfolioText: {
    title: 'בוא נתחיל לפצח ביחד',
    textBtn: 'צרו איתי קשר',
    textDown: 'יובל המובלבל'
  },
  tempoPage: {
    title: 'טמפו',
    subtitleStart: 'ברוכים הבאים לדיגיטל, לאחר שנים רבות הגיע הזמן לרענן,  ',
    subtitleFinish: 'להתחדש ולשפר את התקשורת מול מאות אלפי הלקוחות. ',
    textDescriptionTitle: 'לחיים! עולם המשקאות הושק',
    textDescription: 'איך עושים סדר וחשק לקטלוג עצום של אלפי סוגי מוצרים?\n' +
    'חילקתי הכל לתוך עולמות חווייתיים שנישאר רק לצלול לתוכם ולרצות לגימה.\n' +
    'פרוייטק ממסטל.\n'
  },
  yuvalPage: {
    firstTitle: 'שקשוקה על הראש',
    firstText: 'הילדים חופרים? בא לך שנצ?\n' +
    'האפליקציה עוזרת לנו ההורים, בהתמודדות העברת המסרים לילד והחשיבות של הפעולות היומיומיות השונות. יאללה, לכו לצחצח שיניים.\n',
    secondTitle: 'לדבר בשפה שלהם',
    secondText: 'מעבר לקול היפה של יובל, הניווט פשוט וקבוע, הצבעוניות מאירת עיניים והמשחקיות יגרמו לילדכם להישאב לעולם.',
    thirdTitle: 'הכל מתחיל בעיפרון ומחק',
    thirdText: 'עושים כמה קישקושים ומגישים לאישור.\n' +
    'ככה כמה פעמים ובסוף מצליחים. אין כמו מגע העיפרון על הנייר בכדי להוריד דימיון למציאות.\n',
    fourTitle: 'יאללה, ליצירת המשחק',
    fourText: 'איזה משחקים? איך מגבירים מודעות? איך בוחרים נושאים שחשובים יותר?\n' +
    'איך גורמי לילד בן שנתיים להבין מה לעשות?\n' +
    'אתגר גדול, שעות של התבוננות ונסיונות על הילדים שלי.\n',
    fiveTitle: 'אפיון הדמות',
    fiveText: 'לשמור על זהותו של יובל ולהמציא אותו מחדש.\n' +
    'השמירה על הכוכב והכובע שכל כך מאפיינים את הדמות.\n' +
    'איור קלאסי.\n',
    sixTitle: 'בואו נכניס קצת צבע ותנועה',
    sixText: 'עכשיו מתחיל הפרוייקט, עשרות אלפי פריימים של תזוזות.\n' +
    'הכח להמשיך נובע מההנאה הצפוייה של הילדים מהמשחק.\n' +
    'ושל אישתי ושלי בשישי בצהריים בשנצ :)\n',

  },
  samsungPage: {
    title: 'לא כקלישאה, ממש לעזור למבוגרים קשיי יכולת להישאר עצמאיים בביתם זה פרוייקט שריגש אותי.\n' +
    'בריאות לכולם. ואמרו אמן.\n',
    secondFloor: {
      mainTitle: 'עיצוב הממשק',
      minorTitle: 'בעיות ראייה, אצבעות רועדות, שמיעה חלשה הן חלק מהאתגרים.\n' +
      'גודל הפונטים והכפתורים היו המפתח העיקרי לחוויית המשתמש הטובה.\n',
      minorTitleMobile: 'בעיות ראייה, אצבעות רועדות, שמיעה חלשה הן חלק מהאתגרים.\n' +
      'גודל הפונטים והכפתורים היו המפתח העיקרי לחוויית המשתמש הטובה.\n'
    },
    thirdFloor: {
      mainTitle: 'עיצוב ממשק למשפחה',
      minorTitle: 'להיות עם האצבע על הדופק ולסמוך על האפליקציה שתדע להתריע במקרה חירום.\n' +
      'כאן, הממשק מותאם למשתמשים שמחוברים יותר לאפליקציות יום-יום.\n',
      minorTitleMobile: 'להיות עם האצבע על הדופק ולסמוך על האפליקציה שתדע להתריע במקרה חירום.\n' +
      'כאן, הממשק מותאם למשתמשים שמחוברים יותר לאפליקציות יום-יום.\n'
    },
    fourthFloor: {
      mainTitle: 'ממשק רופאים',
      minorTitle: 'ריבוי נתונים רפואיים לפי סולם דרגות חומרה מצריך ממשק יעיל בעל יכולות ai.\n' +
      'ניווט יעיל, היררכיות צבעוניות, הדגשות במסך ועוד, מקלות על רופא טרוד במשמרת.\n',
      minorTitleMobile: 'ריבוי נתונים רפואיים לפי סולם דרגות חומרה מצריך ממשק יעיל בעל יכולות ai.\n' +
      'ניווט יעיל, היררכיות צבעוניות, הדגשות במסך ועוד, מקלות על רופא טרוד במשמרת.\n'
    },
    fifthFloor: {
      mainTitle: 'ללכת עם ולהרגיש בלי',
      minorTitle: 'הרעיון בסופו של דבר הוא לאפשר לגיל השלישי והרביעי עצמאיות בביתם ובחוץ.\n' +
      'במקביל, הילדים והמוקד הרפואי מתעדכנים רק שצריך באמת.\n'
      ,
      minorTitleMobile: 'הרעיון בסופו של דבר הוא לאפשר לגיל השלישי והרביעי עצמאיות בביתם ובחוץ.\n' +
      'במקביל, הילדים והמוקד הרפואי מתעדכנים רק שצריך באמת.\n'
    },
    sixthFloor: {
      mainTitle: 'תכנון ואפיון UX',
      minorTitle: 'סוף עיצוב במחשבה תחילה.\n' +
      'לחשוב על כל הפיצ׳רים עתידיים, המגבלות של הגיל השלישי והרביעי וליישם הכל לאב-טיפוס.\n',
      userExp: 'my user\n' +
      'experience',
      minorTitleMobile: 'סוף עיצוב במחשבה תחילה.\n' +
      'לחשוב על כל הפיצ׳רים עתידיים, המגבלות של הגיל השלישי והרביעי וליישם הכל לאב-טיפוס.\n'
    }
  },
  contactPage: {
    title: 'דברו איתי',
    description: 'נרדמתי על המסך אחרי לילה לבן, כבר חוזר אליך..\n' +
    '\n' +
    'אם לא, כתוב לי אימייל sefibogin@gmail.com\n' +
    'או שבכל זאת תתקשר שוב 054-2343728\n',
    descriptionMob: 'נרדמתי על המסך אחרי לילה לבן, כבר חוזר אליך..\n' +
    '\n' +
    'אם לא, כתוב לי אימייל sefibogin@gmail.com\n' +
    'או שבכל זאת תתקשר שוב 054-2343728\n',
  },
  aboutPage: {
    titleElipse1: 'נעים להכיר',
    titleElipse2: 'מה עושה?',
    titleElipse3: 'איך זה עובד?',
    description1: 'פרופ׳ ספי בוגין, חי ונושם את תחום הדיגיטאל כבר 20 שנה.\n' +
    'אבא לשלושה, מכור לנחשי גומי ונאמן ל-Apple.\n' +
    '\n' +
    'למי שאין כח לחפירות, שילחץ למטה על Play ויראה מה הסרט שלי.\n' +
    '\n' +
    'דוגרי, לפני עסקים חייב להיות חיבור בין אנשים, בלי פוליטיקה או בירוקרטיה ארגונית, אלו אוייבי המוזה וחדוות היצירה שלי.\n' +
    'הרבה שנים וצלקות (מיקצועיות) על הפנים הביאוני עד הלום, זה אני.\n' +
    '\n' +
    '(איזה פרופסור.. בקושי סיימתי תיכון)\n',
    description1Mobile: 'פרופ׳ ספי בוגין, חי ונושם את תחום הדיגיטאל כבר 20 שנה.\n' +
    'אבא לשלושה, מכור לנחשי גומי ונאמן ל-Apple.\n' +
    '\n' +
    'למי שאין כח לחפירות, שילחץ למטה על Play ויראה מה הסרט שלי.\n' +
    '\n' +
    'דוגרי, לפני עסקים חייב להיות חיבור בין אנשים, בלי פוליטיקה או בירוקרטיה ארגונית, אלו אוייבי המוזה וחדוות היצירה שלי.\n' +
    'הרבה שנים וצלקות (מיקצועיות) על הפנים הביאוני עד הלום, זה אני.\n' +
    '\n' +
    '(איזה פרופסור.. בקושי סיימתי תיכון)\n',
    description2: 'תכל׳ס.. מזיז פיקסלים :) העיניין הוא לסדר אותם בצורה הכי נוחה, יפה ויעילה שתביא את המשתמש לביצוע הפעולה הרצויה. עם השנים נדרשתי ליותר ויותר תחומים על מנת להביא את לקוחותיי להצלחה בעזרת מיתוג, טרנדיות, חוויית משתמש UX, תלת ממד, איור, מוזיקה, פונטים, פוסט וזהו!',
    description2Mobile: 'תכל׳ס.. מזיז פיקסלים :) העיניין הוא לסדר אותם בצורה הכי נוחה, יפה ויעילה שתביא את המשתמש לביצוע הפעולה הרצויה. עם השנים נדרשתי ליותר ויותר תחומים על מנת להביא את לקוחותיי להצלחה בעזרת מיתוג, טרנדיות, חוויית משתמש UX, תלת ממד, איור, מוזיקה, פונטים, פוסט וזהו!',
    description3: 'חיבוק עצים פעמיים בשבוע, במטע הזיתים של אלפי מנשה.\n' +
    'התחלת תהליך מתבטא במחיקת כל מה שאני מכיר, מה שהמשתמש צריך זו הנחת העבודה שלי.\n' +
    'ואז למחקר, בחינת המתחרים, תכנון אסטרטגי, פיצוח קריאטיבי וביצוע העיצוב ברמת גימור ״גרמניה״.\n',
    description3Mobile: 'חיבוק עצים פעמיים בשבוע, במטע הזיתים של אלפי מנשה.\n' +
    'התחלת תהליך מתבטא במחיקת כל מה שאני מכיר, מה שהמשתמש צריך זו הנחת העבודה שלי.\n' +
    'ואז למחקר, בחינת המתחרים, תכנון אסטרטגי, פיצוח קריאטיבי וביצוע העיצוב ברמת גימור ״גרמניה״.\n'
  },

};
export const INFO_PROJECT_HOME = {
  textAll: 'וואלאק ברוך השם, אחד אחד, כל פרוייקט בובה.\n' +
  'כנסו, תלמדו ותחוו את התהליך.\n' +
  'אם זה מצית בכם כיוון לפרוייקט שיושב על שולחנכם - ת׳קשרו.\n',
  textAllMob: 'וואלאק ברוך השם, אחד אחד, כל פרוייקט בובה.\n' +
  'כנסו, תלמדו ותחוו את התהליך.\n'
};

