<app-video-modal #openVideoFull *ngIf="modalIsShown" (modalClosed)="toggleModal()" @easeInOut [videoSrc]="'https://player.vimeo.com/external/258431595.hd.mp4?s=cfb7abb09de64b93303f1db662f3fb2e54c211a6&profile_id=175'"></app-video-modal>
<div class="main_block">
  <div *ngIf="isMobile" [innerHtml]="videoHtml"></div>
    <video poster="/assets/img/sefiPoster/sefi-floor-1-video-without-effect_1.jpg" *ngIf="!isMobile" #videoStarts [src]="!isMobile ? 'https://player.vimeo.com/external/261800031.hd.mp4?s=3fc2b2f807793f4452620f2ecbe7341a92d58005&profile_id=175' : 'https://player.vimeo.com/external/261800031.hd.mp4?s=3fc2b2f807793f4452620f2ecbe7341a92d58005&profile_id=175'" playsinline loop muted autoplay></video>
  <div class="inner_block">
    <div class="nav_menu">
      <img *ngIf="!isMobile" src="/assets/img/home/background_menu.png" class="menu_background">
      <app-share-menu *ngIf="!isMobile"></app-share-menu>
    </div>
    <div class="circle">
      <span [innerText]="TEXTS.SefiBogin" class="name"></span>
      <span [innerText]="TEXTS.UXDesign"></span>
      <div class="play_with_label">
        <img *ngIf="!iphone && isMobile" [src]="!isMobile ? '/assets/img/home/icon_play.png' : '/assets/img/home/Mobile/play_button.png'" class="play" (click)="toggleModal()">
        <img *ngIf="!isMobile" [src]="!isMobile ? '/assets/img/home/icon_play.png' : '/assets/img/home/Mobile/play_button.png'" class="play" (click)="toggleModal()">
        <img *ngIf="!modalIsShown && iphone && isMobile" [src]="!isMobile ? '/assets/img/home/icon_play.png' : '/assets/img/home/Mobile/play_button.png'" class="play" (click)="toggleModal()">
        <img *ngIf=" modalIsShown && iphone && isMobile" src="/assets/img/home/preloader-sm.gif" class="play" (click)="toggleModal()">
        <img [src]="!isMobile ? '/assets/img/home/label.png' : '/assets/img/home/Mobile/play_label.png'" class="label">
        <!--<div *ngIf="modalIsShown" @bulb class="bulb"></div>-->
      </div>
    </div>
  </div>
</div>
