import {
  AfterViewInit,
  Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output,
  ViewChild
} from '@angular/core';
import {TEXTS} from '../../common/texts';
import {CoefService} from '../../services/coef.service';
import * as parallaxer from 'parallaxer';
import {Subscription} from 'rxjs/Subscription';
import {BULB, FADE_IN_OUT} from '../../common/animations';
import {ScrollService} from '../../services/scroll.service';
import {PageService} from '../../services/page.service';
import {Observable} from 'rxjs/Observable';
import {ScrollUpDownService} from '../../services/scrollUpDown.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  animations: [
    FADE_IN_OUT,
    BULB
  ]
})
export class MainMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() projectSection = new EventEmitter();
  private streams$: Subscription[] = [];

  menuIsShown = false;
  private parallax;
  readonly TEXTS = TEXTS;
  isMobile: boolean;
  modalIsShownVideo = false;

  private coef: number;
  private scrollPosition: number;

  @Input() fadeInOn: number;
  @Input() stickOn: number;
  @Input() stickOnMob: number;
  @ViewChild('menuButton') menuButton: ElementRef;
  @Output() menuClosed = new EventEmitter<any>();

  constructor(private coefService: CoefService,
              private scrollService: ScrollService,
              private pageService: PageService,
              private scrollUpDownService: ScrollUpDownService) {
    this.isMobile = this.coefService.getDeviceType();
    if (this.stickOnMob) {
      // this.menuButton.nativeElement.style.top = '800px';
    }
  }

  routeHome() {
    // console.log('asdas');
    this.pageService.goHome();
  }

  VideoOpen() {
    this.toggleMenu();
    this.toggleModalVideo();
    // this.pageService.goAbout();
  }

  goAbout() {
    this.pageService.goAbout();
  }

  goContact() {
    this.pageService.goContact();
  }

  goWalla() {
    this.menuIsShown = !this.menuIsShown;
    if (this.menuIsShown === true) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
    this.projectSection.emit();
    this.scrollUpDownService.homePageProjectScroll = true;
    localStorage.setItem('homePageProjectScroll' , 'true');
    this.pageService.goHome();
  }

  ngOnInit() {

    this.coef = this.coefService.getCoef();
    this.streams$.push(this.coefService.coefHasChanged.subscribe(
      (newCoef: number) => {
        this.coef = newCoef;
        if (!this.isMobile) {
          // this.parallax();
          // this.parallaxInit();
        }
      }
    ));

    this.streams$.push(this.scrollService.scrollPositionHasChanged.subscribe(
      (newScrollPosition: number) => {
        this.scrollPosition = newScrollPosition;
        if (this.scrollPosition > this.fadeInOn * this.coef) {
          this.menuButton.nativeElement.style.display = 'flex';
        }

        if (this.isMobile) {
          if (this.scrollPosition > this.stickOn * this.coef - 120) {
            this.menuButton.nativeElement.style.top = '120px';
            this.menuButton.nativeElement.style.position = 'fixed';
            this.menuButton.nativeElement.style.display = 'flex';
          } else {
            this.menuButton.nativeElement.style.top = this.stickOn * this.coef + 'px';
            this.menuButton.nativeElement.style.position = 'absolute';
            this.menuButton.nativeElement.style.display = 'flex';
            // if (this.stickOnMob) {
            //   this.menuButton.nativeElement.style.top = '800px';
            // }
          }
        } else {
          if (this.scrollPosition > this.stickOn * this.coef - 41) {
            this.menuButton.nativeElement.style.top = '41px';
            this.menuButton.nativeElement.style.position = 'fixed';
            this.menuButton.nativeElement.style.display = 'flex';
          } else {
            this.menuButton.nativeElement.style.top = this.stickOn * this.coef + 'px';
            this.menuButton.nativeElement.style.position = 'absolute';
            this.menuButton.nativeElement.style.display = 'flex';
          }
        }
        if (this.isMobile) {
          if (this.stickOnMob) {
            if (this.scrollPosition > this.stickOnMob * this.coef - 120) {
              this.menuButton.nativeElement.style.top = '120px';
              this.menuButton.nativeElement.style.position = 'fixed';
              this.menuButton.nativeElement.style.display = 'flex';
            } else {
              this.menuButton.nativeElement.style.top = this.stickOnMob * this.coef + 'px';
              this.menuButton.nativeElement.style.position = 'absolute';
              this.menuButton.nativeElement.style.display = 'flex';
              // if (this.stickOnMob) {
              //   this.menuButton.nativeElement.style.top = '800px';
              // }
            }
          }
        }
      }
    ));

  }

  parallaxInit() {
    // const menuButton = this.menuButton.nativeElement;
    // this.parallax = parallaxer([{
    //   element: menuButton,
    //   offset: this.stickOn * this.coef,
    //   stick: true,
    //   stickOffset: 40
    // }]);

  }

  toggleMenu() {
    this.menuIsShown = !this.menuIsShown;
    if (this.menuIsShown === true) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }

  ngAfterViewInit() {
    this.parallaxInit();

    this.scrollService.getScrollPosition().then(e => {
      this.scrollPosition = +e;

      if (this.scrollPosition > this.stickOn * this.coef) {
        this.menuButton.nativeElement.style.top = '41px';
        this.menuButton.nativeElement.style.position = 'fixed';
        this.menuButton.nativeElement.style.display = 'flex';
      } else {
        this.menuButton.nativeElement.style.top = this.stickOn * this.coef + 'px';
        this.menuButton.nativeElement.style.position = 'absolute';
        this.menuButton.nativeElement.style.display = 'flex';
        if (this.stickOnMob) {
          this.menuButton.nativeElement.style.top = '800px';
        }
      }
    });
  }

  toggleModalVideo() {
    this.modalIsShownVideo = !this.modalIsShownVideo;
    if (this.modalIsShownVideo === true) {
      // document.documentElement.style.overflow = 'hidden';
    } else {
      // document.documentElement.style.overflowY = 'scroll';
      // this.openVideoFull.openVideo();
    }
  }

  ngOnDestroy() {
    for (const stream$ of this.streams$) {
      stream$.unsubscribe();
    }
  }

}
