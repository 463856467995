<div class="menu_button" (click)="toggleMenu()" #menuButton>
  <img src="/assets/img/home/button-menu.png">
  <div *ngIf="menuIsShown"  @bulb class="bulb"></div>
</div>
<app-video-modal #openVideoFull *ngIf="modalIsShownVideo" (modalClosed)="toggleModalVideo()" @easeInOut [videoSrc]="'https://player.vimeo.com/external/258431595.hd.mp4?s=cfb7abb09de64b93303f1db662f3fb2e54c211a6&profile_id=175'"></app-video-modal>
<div class="menu" *ngIf="menuIsShown" @easeInOut>
  <div class="close_row" *ngIf="!isMobile" style="position: relative; top: 2%;" >
    <a class="close_button" (click)="toggleMenu()"></a>
  </div>

    <div class="menu_header" (click)="routeHome()" style="cursor: pointer">
      <span [innerText]="TEXTS.SefiBogin"></span>
      <img (click)="routeHome()" src="/assets/img/home/menu_logo.png">
    </div>

    <ul>
      <li><a (click)="goAbout()"  [innerText]="TEXTS.big_menu.who"></a></li>
      <li><a (click)="goWalla()"  scrollTo  scrollTargetSelector="#toThisElement" [innerText]="TEXTS.big_menu.projects"></a></li>
      <li><a (click)="VideoOpen()" [innerText]="TEXTS.big_menu.history"></a></li>
      <li><a (click)="goContact()" [innerText]="TEXTS.big_menu.contact"></a></li>
    </ul>
      <img src="/assets/img/home/closeMenuMobile.png" class="close_button_mobile" (click)="toggleMenu()" *ngIf="isMobile">


</div>
