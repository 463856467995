import {HostListener, Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {CoefService} from './coef.service';
import {_catch} from 'rxjs/operator/catch';



@Injectable()
export class ScrollUpDownService {
  homePageProjectScroll = false;
  homePageProjectScrolls = false;
  eventMousewheel = new Subject<any>();
  constructor(private coefService: CoefService) {
    if (!coefService.getDeviceType()) {
        Observable.fromEvent(window, 'wheel').subscribe((e) => {
          this.mouseWheelFunc(event);
        });
      window.addEventListener ('DOMMouseScroll', () => {
            this.mouseWheelFunc();
      } , false);
    }else{
      Observable.fromEvent(window, 'touchmove').subscribe((e) => {
        this.mouseWheelFunc(event);
      });
    }
  }
  mouseWheelFunc(event?) {
    this.eventMousewheel.next(event);
  }
}
