<div (click)="goYuval()" style="cursor: pointer" class="Yuval">
  <img class="background" [src]="isMobile ? '/assets/img/home/Mobile/BgYuval.png': '/assets/img/home/BgYuval.png'" src="" alt="">
  <div class="wraper_container">
    <div class="col1">
      <div [class]="isMobile ? '' : 'wraper_info_yuval'">
        <p class="logoText">יובל המובלבל</p>
        <p class="description">
          <!--{{TEXT.textYuvalHome}}-->
          יובל לא התבלבל כלל כאשר הבין את כח <br> ה-Gaming,  כערוץ הפצת תכנים וחיזוק מותג.
          אפליקציה חינוכית מרהיבה העוזרת <br> בהתמודדות הילדים בפעולות היומיומיות השונות.
        </p>
        <p class="hashtag hashtagHome">{{!isMobile ? TEXT.hashtag : TEXT.hashtag2}}</p>
        <div class="wrapper_btn">
          <a class="btn styleBtnHome" (click)="goYuval()">{{TEXT.btnWalla}}</a></div>
      </div>
      <!--<p class="titleBig" [@scrolledState]="animationState">SEFI BOGIN</p>-->
      <p class="titleBig" >SEFI BOGIN</p>
    </div>
    <div class="col2">
      <img class="illustration" [src]="isMobile ? '/assets/img/home/Mobile/ilustration_Yuval.png' : '/assets/img/home/ilustration_Yuval.png'" src="" alt="">
    </div>
  </div>
</div>



