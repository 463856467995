import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class CoefService {
  isMobile = false;
  coefHasChanged = new Subject<any>();

  private coef = window.innerWidth / 1920;

  constructor( private domSanitizer: DomSanitizer) {
    this.subscribeWindowResize();
  }

  videoHtml(url , id? , poster? , dataUrl?) {
    return this.domSanitizer.bypassSecurityTrustHtml(
      `<video ${poster} id="${id}" style="width: 100%;" playsinline loop muted autoplay data-url="${dataUrl}">
          <source src="${url}" type="video/mp4">No HTML5 supported.</source>
       </video>`
    );
  }
  getCoef() {
    return this.coef;
  }

  changeCoef() {
    this.coef = window.innerWidth / 1920;
    this.coefHasChanged.next(this.coef);
  }

  subscribeWindowResize() {
    Observable.fromEvent(window, 'resize')
      .debounceTime(500)
      .subscribe(event => {
        /**
         * send window resize event to dashboard service
         * to resize golden layout
         */
        this.changeCoef();
      });
  }

  getDeviceType() {
    if (window.innerWidth  < 769) {
      this.isMobile = true;
    }
    return this.isMobile;
  }

  getDomain() {
    return window.location.origin;
  }

}
