import {animate, state, style, transition, trigger,  group, animateChild, query, stagger, } from '@angular/animations';

export const BULB = trigger('bulb', [
  transition(':enter', [
    style({
      // opacity: 0,
      transform: 'scale(0)'
    }),
    animate('.5s ease-in-out', style({
      // opacity: 1,
      transform: 'scale(30)'
    })),
    animate('1s ease-in-out', style({
      // opacity: 1,
      transform: 'scale(30)'
    }))
  ]),
  transition(':leave', [
    style({
      // opacity: 1,
      transform: 'scale(30)'
    }),
    animate('.5s ease-in-out', style({
      // opacity: 0,
      transform: 'scale(0)'
    }))
  ])
]);

export const FADE_IN_OUT = trigger('easeInOut', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('.3s 0.79s ease-in-out', style({
      opacity: 1,
    }))
  ]),
  transition(':leave', [
    style({
      opacity: 1
    }),
    animate('.5s ease-in-out', style({
      opacity: 0
    }))
  ])
]);
export const PORTFOLIO_UP = trigger('scrolledState', [
  state('hidden', style({
    position: 'absolute',
    bottom: 0,
    left: 0,
    marginBottom: '-17%',
    width: '100%',
    height: '20%'
  })),
  state('shown', style({
    position: 'absolute',
    bottom: 0,
    left: 0,
    marginBottom: '0',
    width: '100%',
    height: '20%'
  })),
  transition('hidden => shown', animate('0.75s ease-out'))
]);
export const PORTFOLIO_FADE_OUT = trigger('fadeOut', [
  transition(':leave', [
    style({
      opacity: 1
    }),
    animate('1s linear', style({
      opacity: 0
    }))
  ])
]);


export const routerTransition = trigger('routerTransition', [
  transition('* <=> *', [
    /* order */
    /* 1 */ query(':enter, :leave', style({  width: '100%' , height: '100vh' })
      , { optional: true }),
    /* 2 */ group([  // block executes in parallel
      query(':enter', [
        style({ position: 'absolute' , zIndex: 0 , transform: 'translateY(0%)' }),
        animate('0s ease-in-out', style({   transform: 'translateY(0%)' }))
      ], { optional: true }),
      query(':leave', [
        style({ position: 'fixed' ,  zIndex: 5 , transform: 'translateY(0%)' }),
        animate('1s  ease-in-out', style({  transform: 'translateY(100%)' }))
      ], { optional: true }),
    ])
  ])
])
