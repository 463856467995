<div (click)="goToTanka()" style="cursor: pointer" class="tanka" [ngClass]="{'mobileClass': isMobile}">
  <img class="background"
       [src]="isMobile ? '/assets/img/home/Tanka/tanka_bg_mobile.png': '/assets/img/home/Tanka/tanka_home.png'"
       src="" alt="">
  <div class="wraper_container">
    <div class="col1">
      <div [class]="isMobile ? 'mobile_wrapper_info' : 'wraper_info'">
        <img src="/assets/img/home/Tanka/tanka_name.png" style="width: 60%;"/>
        <p class="description">
          צמיד פלסטיק בטכנולוגיה ייחודית המשחרר למחזור
          <br/>
          הדם CBD (חומרים מצמח הקאנביס), הפרויקט <br/>
          הראשון שעשה אותי שמייח, לאורך כל העשייה <br/>
          ודאג לי לאספקה שוטפת.
        </p>


        <p class="hashtag hashtagHome">{{!isMobile ? TEXT.hashtag : TEXT.hashtag2}}</p>
        <div class="wrapper_btn">
          <a class="btn styleBtnHome" (click)="goToTanka()">{{TEXT.btnWalla}}</a></div>


      </div>
      <!--<p class="titleBig" [@scrolledState]="animationState">SEFI BOGIN</p>-->
      <p class="titleBig" *ngIf="!isMobile">SEFI BOGIN</p>
    </div>
    <div class="col2">
      <img class="illustration"
           [src]="isMobile ? '' : '/assets/img/home/Tanka/man.png'"
           src="" alt="">
    </div>
  </div>


</div>

<div class="whiteParralexMobile"></div>


