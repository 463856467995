import {Component, OnInit} from '@angular/core';
import {TEXTS} from '../../../common/texts';
import {BULB, FADE_IN_OUT} from '../../../common/animations';
import {CoefService} from '../../../services/coef.service';
import {PageService} from '../../../services/page.service';

@Component({
  selector: 'app-home-twelfth',
  templateUrl: './home-twelfth.component.html',
  styleUrls: ['./home-twelfth.component.scss'],
  animations: [
    FADE_IN_OUT
  ]
})
export class HomeTwelfthComponent implements OnInit {
  readonly TEXTS = TEXTS;
  isMobile: boolean;

  moreLogosAreShown = false;
  moreLogosAreShown2 = false;

  constructor(private coefService: CoefService,
              private pageService: PageService) {
    this.isMobile = this.coefService.getDeviceType();
  }

  ngOnInit() {
  }

  toogleMoreLogosVisibility() {
    if (this.moreLogosAreShown) {
      if (this.moreLogosAreShown2) {
        this.pageService.goContact();
      }
      this.moreLogosAreShown2 = true;
    }
    this.moreLogosAreShown = true;
    console.log(this.moreLogosAreShown, this.moreLogosAreShown2);
  }

}
