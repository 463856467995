<div (click)="goSamsung()" style="cursor: pointer" class="sumsung">
  <img class="background" [src]="isMobile ? '/assets/img/home/Mobile/BgSumsung.png' : '/assets/img/home/BgSumsung.png'" src="" alt="">
  <div class="wraper_container">
    <div class="col2">
      <img class="illustration" [src]="isMobile ? '/assets/img/home/Mobile/illustration-sumsung.png' : '/assets/img/home/illustration-sumsung.png'"  alt="">
    </div>
    <div class="col1">
      <!--<p class="titleBig">SEFI BOGIN</p>-->
      <div>
        <img [src]="isMobile ? '/assets/img/elements/sumsungMob.png' : '/assets/img/elements/sumsung.png'" alt="">

        <p class="description"> {{TEXT.textSumsungHome}}</p>
        <p class="hashtag hashtagHome">{{!isMobile ? TEXT.hashtag : TEXT.hashtag2}}</p>
        <div class="wrapper_btn hashtagHome">
          <a (click)="goSamsung()" class="btn styleBtnHome" >{{TEXT.btnWalla}}</a>
        </div>
      </div>
    </div>
  </div>
</div>


