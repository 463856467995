<div (click)="goTempo()" style="cursor: pointer" class="tempo">
  <img class="background"
       [src]="!isMobile ? '/assets/img/home/BgTempoSection.png' : '/assets/img/home/Mobile/bg_tempo.png'"
       alt="">
  <div class="wraper_container">
    <div class="col2">
      <img  class="beer"
           [src]="!isMobile ? '/assets/img/home/beer.png' : '/assets/img/home/Mobile/tempo_bottle.png'"
           alt="">
      <img
        [src]="!isMobile ? '/assets/img/home/beer_background.png' : '/assets/img/home/Mobile/tempo_bottle_bg.png'"
        class="beer_background" alt="">
    </div>
    <div class="col1">
      <!--<p class="titleTempo" *ngIf="!isMobile" [@scrolledState]="animationState">tempo</p>-->
      <p class="titleTempo" *ngIf="!isMobile" >tempo</p>
      <div>
        <img
          [src]="!isMobile ? '/assets/img/elements/tempo_white.png' : '/assets/img/home/Mobile/logo_tempo.png'"
          alt="">
        <p class="description"> {{TEXT.textTempoHome}}</p>
        <p class="hashtag hashtagHome" [innerText]="!isMobile ? TEXT.hashtag : TEXT.hashtag2"></p>
        <div class="wrapper_btn">
          <a (click)="goTempo()" class="btn styleBtnHome">{{TEXT.btnWalla}}</a></div>
      </div>
    </div>
  </div>
</div>
