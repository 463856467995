<header [ngStyle]="{'padding-left':padding}" class="header">
  <nav class="wrapper_link">
    <span class="link" (click)="goAbout()">מי? מה? איך?</span>
    <!--<span class="link" (click)="goWalla() ">פרוייקטים</span>-->
    <span class="link" id="triggerClick" (click)="goWalla()">פרוייקטים</span>
    <span class="link" (click)="goContact()">צור קשר</span>
  </nav>
  <div class="wrapLogosefi" (click)="goHome()">
    <!--<img class="logoSefi" src="/assets/img/header/sb_header.png" alt="">-->
    <svg class="logoSefi" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
         preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 62 62">
      <defs>
        <g id="Layer0_0_FILL">
          <path fill="#FFFFFF" stroke="none" d="
M 62 3
Q 62 1.75 61.1 0.9 60.25 0 59 0
L 3 0
Q 1.75 0 0.85 0.9 0 1.75 0 3
L 0 59
Q 0 60.3 0.85 61.15 1.75 62 3 62
L 59 62
Q 60.25 62 61.1 61.15 62 60.25 62 59
L 62 3
M 41.75 29.35
Q 44.55 29.35 46.3 31.45 48.05 33.6 48.05 37 48.05 40.85 45.9 43.1 43.95 45.15 41.2 45.15 37.95 45.15 36.4 42.55
L 36.35 42.55 36.2 44.8 32.9 44.8 33.05 22.8 36.85 22.8 36.85 31.8 36.9 31.8
Q 38.45 29.35 41.75 29.35
M 23.55 19
Q 27.05 19 29.5 20.25
L 28.35 23.9
Q 26.2 22.7 23.5 22.7 21.5 22.7 20.35 23.65 19.35 24.45 19.35 25.65 19.35 27 20.5 27.95 21.45 28.7 24.1 29.7 27.35 30.95 28.9 32.65 30.45 34.4 30.45 37.05 30.45 40.2 28.15 42.2 25.65 44.4 21.1 44.4 17.05 44.4 14.45 42.85
L 15.45 39.15
Q 18.15 40.75 21.4 40.75 23.5 40.75 24.75 39.8 25.9 38.9 25.9 37.35 25.9 36 24.85 35.05 23.9 34.15 21.5 33.25 14.85 30.9 14.85 26.1 14.85 23.05 17.2 21.05 19.6 19 23.55 19
M 43.15 40.8
Q 44.15 39.45 44.15 37.15 44.15 35.05 43.2 33.75 42.2 32.35 40.35 32.35 39.2 32.35 38.25 33.1 37.3 33.9 37 35.15
L 36.85 36.15 36.85 38.55 36.95 39.45
Q 37.25 40.6 38.15 41.35 39.1 42.15 40.3 42.15 42.1 42.15 43.15 40.8 Z"/>
        </g>
      </defs>

      <g transform="matrix( 1, 0, 0, 1, 0,0) ">
        <use xlink:href="#Layer0_0_FILL"/>
      </g>
    </svg>
  </div>

</header>
<div class="wrapper_modal_play_video" *ngIf="modal_play_video && !isMobile" @fadeOut>
  <div class="modal_play_video">

    <div class="left_block_modal">
      <!--<img src="../../../assets/gif/kid-stand.gif" class="kid_stand" alt="">-->
      <img src="/assets/gif/kid-stand.gif" class="kid_stand" alt="">
      <div class="earth"></div>
      <!--<img (click)="close_modal_play_video()" src="../../../assets/img/header/btn_close_modal.png"  class="btn_close_modal" alt="">-->
      <img (click)="close_modal_play_video()" src="/assets/img/header/btn_close_modal.png" class="btn_close_modal" alt="">
    </div>

    <div class="right_block_modal">
      <div class="text">
        <p class="text_1">עכשיו</p>
        <p class="text_2">באים?</p>
      </div>
      <div class="buttons">
        <span (click)="close_modal_play_video()" class="button">שים קפה</span>
        <span (click)="close_modal_play_video()" class="button">רק מים</span>
      </div>

    </div>

  </div>
</div>

