import {Component, OnInit} from '@angular/core';
import {ScrollService} from './services/scroll.service';
import {routerTransition} from './common/animations';
import {  Compiler } from '@angular/core';


@Component({
  selector: 'app-root',
  animations: [ routerTransition ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit {
  public test = false;
  // getRouteAnimation(outlet) {
  //   if (this.scrollService.animationTOpDowan) {
  //     return outlet.activatedRouteData ;
  //   }
  // }
  constructor(private scrollService: ScrollService ,private _compiler: Compiler ) {
    // this._compiler.clearCache();
    let  loadPage =  localStorage.getItem('loadPage');
    console.log(loadPage);
    let routUp = localStorage.getItem('routUp');
    if(!loadPage ) {
      localStorage.setItem('loadPage', 'false');
    }
    if(!routUp){
      localStorage.setItem('routUp', 'false');
    }

  }
  ngOnInit() {
    // window.scrollTo(0, 0);
  }

}

