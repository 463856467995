import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CoefService} from '../../../services/coef.service';
import {Subscription} from 'rxjs/Subscription';
import {ScrollService} from '../../../services/scroll.service';
import {OptimazeHome} from '../../../services/optimazeHome';
import {DomSanitizer} from '@angular/platform-browser';

declare var jQuery: any;

@Component({
  selector: 'app-home-third',
  templateUrl: './home-third.component.html',
  styleUrls: ['./home-third.component.scss']
})

export class HomeThirdComponent implements OnInit, OnDestroy, AfterViewInit {
  private streams$: Subscription[] = [];

  private coef: number;
  private scrollPosition: number;
  isMobile: boolean;
  videoHtml;

  @ViewChild('videoPlayer') videoplayer: any;

  constructor(private coefService: CoefService,
              private scrollService: ScrollService, private optimazeHome: OptimazeHome, private domSanitizer: DomSanitizer) {
    this.isMobile = this.coefService.getDeviceType();
  }

  ngOnInit() {
    // this.videoHtml = this.domSanitizer.bypassSecurityTrustHtml(
    //   `<video poster="/assets/img/home/Screenshot_1.png" style="width: 100%;" playsinline loop muted autoplay>
    //       <source src="https://player.vimeo.com/external/258416862.sd.mp4?s=e499c88ce42c57aaeeed7862db254908c0855934&profile_id=165" type="video/mp4">No HTML5 supported.</source>
    //    </video>`
    // );
    setTimeout(() => {
      // jQuery('#videoSrc').src('https://player.vimeo.com/external/258416862.sd.mp4?s=e499c88ce42c57aaeeed7862db254908c0855934&profile_id=165');
      jQuery('#videoSrc').attr('src', 'https://player.vimeo.com/external/258416862.sd.mp4?s=e499c88ce42c57aaeeed7862db254908c0855934&profile_id=165');
    }, 100);
    this.videoHtml =  this.coefService.videoHtml('', 'videoSrc', `poster="/assets/img/home/Screenshot_1.png"`);
  }

  ngAfterViewInit() {
    if (!this.isMobile) {
      let video = this.videoplayer.nativeElement;
      this.optimazeHome.stopVideo(video, 1500, 3000);
    }
  }

  ngOnDestroy() {
    this.optimazeHome.unSuscribe();
  }
}
