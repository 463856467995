import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class ScrollService {
  scrollPositionHasChanged = new Subject<any>();
  private scrollPosition: number;
  animationTOpDowan = false;
  firstload = false;
  reloadFirst = false;

  constructor() {
    this.subscribeScrollPositionChange();
  }

  getScrollPosition() {
    return new Promise((reject) => {
      setTimeout(() => {
        reject(window.pageYOffset);
      }, 2000);
    });
  }

  changeScrollPosition() {
    this.scrollPosition =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.scrollPositionHasChanged.next(this.scrollPosition);
  }

  subscribeScrollPositionChange() {
    Observable.fromEvent(window, 'scroll').subscribe(() => {
      this.changeScrollPosition();
    });
  }

}
