import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {CoefService} from './coef.service';
import {ScrollService} from './scroll.service';
import {Subscription} from 'rxjs/Subscription';


@Injectable()
export class OptimazeHome {
  private streams$: Subscription[] = [];
  coef;

  constructor(private coefService: CoefService, private scrollService: ScrollService) {

  }

  stopVideo(element, top, bottom, start?) {
    element.pause();
    try {
      if (start) {
        element.play();
      }
    } catch (exc) {

    }

    this.coef = this.coefService.getCoef();
    this.streams$.push(this.coefService.coefHasChanged.subscribe(
      (newCoef: number) => {
        this.coef = newCoef;
      }
    ));
    this.streams$.push(this.scrollService.scrollPositionHasChanged.subscribe(
      (newScrollPosition: number) => {
        if (top * this.coef < newScrollPosition && bottom * this.coef > newScrollPosition) {
          element.play();
        } else {
          element.pause();
        }
      }
    ));
  }

  paralax(element, top, bottom) {
    this.coef = this.coefService.getCoef();
    this.streams$.push(this.coefService.coefHasChanged.subscribe(
      (newCoef: number) => {
        this.coef = newCoef;
      }
    ));
    this.streams$.push(this.scrollService.scrollPositionHasChanged.subscribe(
      (newScrollPosition: number) => {
        if (top * this.coef < newScrollPosition && bottom * this.coef > newScrollPosition) {
          element.style.display = 'block';
        } else {
          element.style.display = 'none';
        }
      }
    ));
  }

  unSuscribe() {
    for (const stream$ of this.streams$) {
      stream$.unsubscribe();
    }
  }
}
