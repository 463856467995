import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import {APP_ROUTES_CONST} from './app.routes';
import {HomeComponent} from './pages/home/home.component';
import {PageService} from './services/page.service';
import { HomeSecondComponent } from './pages/home/home-second/home-second.component';
import { HomeThirdComponent } from './pages/home/home-third/home-third.component';
import { HomeFirstComponent } from './pages/home/home-first/home-first.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {CoefService} from './services/coef.service';
import { HomeFiveComponent } from './pages/home/home-five/home-five.component';
import { HomeSixthComponent } from './pages/home/home-sixth/home-sixth.component';
import { HomeSeventhComponent } from './pages/home/home-seventh/home-seventh.component';
import { HomeEighthComponent } from './pages/home/home-eighth/home-eighth.component';
import { HomeTwelfthComponent } from './pages/home/home-twelfth/home-twelfth.component';
import { HomeNineComponent } from './pages/home/home-nine/home-nine.component';
import { HomeTenComponent } from './pages/home/home-ten/home-ten.component';
import {ScrollService} from './services/scroll.service';
import { HomeElevenComponent } from './pages/home/home-eleven/home-eleven.component';
import {ScrollUpDownService} from './services/scrollUpDown.service';
// import {ScrollToModule} from 'ng2-scroll-to';
import {OptimazeHome} from './services/optimazeHome';
import { HomeTankaComponent } from './pages/home/home-tanka/home-tanka.component';
import {HomeFloatComponent} from "./pages/home/home-float/home-float.component";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    HomeSecondComponent,
    HomeThirdComponent,
    HomeFirstComponent,
    HomeFiveComponent,
    HomeSixthComponent,
    HomeSeventhComponent,
    HomeEighthComponent,
    HomeTwelfthComponent,
    HomeNineComponent,
    HomeTenComponent,
    HomeElevenComponent,
    HomeTankaComponent,
    HomeFloatComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    APP_ROUTES_CONST,
    BrowserAnimationsModule,
    SharedModule,
    // ScrollToModule.forRoot(),
  ],
  providers: [PageService,  CoefService, ScrollService , ScrollUpDownService , OptimazeHome],
  bootstrap: [AppComponent]
})
export class AppModule { }
