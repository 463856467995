<div class="walla" (click)="goWalla()" style="cursor: pointer" #walla>
  <img class="background" [src]="isMobile ? '/assets/img/home/Mobile/backround_wallasec.png' : '/assets/img/home/backround_wallasec.png'"  alt="">
  <div class="wraper_container">
    <div class="col1" [@scrolledState]="animationState">
      <div style="direction: ltr;">
        <img [src]="isMobile ? '/assets/img/elements/walla_whitemob.png' : '/assets/img/elements/walla_white.png'" alt="">
        <p class="description"> {{TEXT.textWallaHome}}</p>
        <p class="hashtag hashtagHome">{{TEXT.hashtag2}}</p>
        <div class="wrapper_btn">
          <a (click)="goWalla()" class="btn" >{{TEXT.btnWalla}}</a></div>
      </div>
    </div>
    <div class="col2">
      <img class="illustration" src="/assets/img/home/illustration-walla.png" alt="">
    </div>
  </div>
</div>


