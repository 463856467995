export const baseUrl = {
  local: 'http://localhost:4200/assets',
  host: 'http://test.betedo.com/videos/'
};
export const PAGE = {
  HOME: '',
  THIRD_PAGE: 'third',
  TEMPO_PAGE: 'tempo',
  FIAT_PAGE: 'fiat',
  WALLA_PAGE: 'walla',
  YUVAL_PAGE: 'yuval',
  HUGGIES_PAGE: 'huggies',
  SAMSUNG_PAGE: 'samsung',
  CONTACT_PAGE: 'contact',
  ABOUT_PAGE: 'about',
  TANKA: 'tanka',
  FLOAT: 'float'
};

export const PAGE_PATH = {
  TANKA: () => import('./pages/portfolio/tanka/tanka.module').then(m => m.TankaModule),
  TEMPO: () => import('./pages/portfolio/tempo/tempo.module').then(m => m.TempoModule),
  FIAT: () => import('./pages/portfolio/fiat/fiat.module').then(m => m.FiatModule),
  WALLA: () => import('./pages/portfolio/walla/walla.module').then(m => m.WallaModule),
  YUVAL: () => import('./pages/portfolio/yuval/yuval.module').then(m => m.YuvalModule),
  HUGGIES: () => import('./pages/portfolio/huggies/huggies.module').then(m => m.HuggiesModule),
  SAMSUNG: () => import('./pages/portfolio/samsung/samsung.module').then(m => m.SamsungModule),
  CONTACT: () => import('./pages/contact-page/contact-page.module').then(m => m.ContactPageModule),
  ABOUT: () => import('./pages/about-me/about-me.module').then(m => m.AboutMeModule),
  FLOAT: () => import('./pages/portfolio/float/float.module').then(m => m.FloatModule)
};
