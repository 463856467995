import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {PageService} from '../../services/page.service';
import {HomeSecondComponent} from './home-second/home-second.component';
import {CoefService} from '../../services/coef.service';
import * as parallaxer from 'parallaxer';
import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/debounceTime';
// import set = Reflect.set;
import {ScrollUpDownService} from '../../services/scrollUpDown.service';
import {ScrollService} from '../../services/scroll.service';
import {OptimazeHome} from '../../services/optimazeHome';
import {PAGE} from '../../router.path';
import {Router} from '@angular/router';
import set = Reflect.set;
import {Meta, Title} from '@angular/platform-browser';
// import {jQuery} from './home-third/home-third.component';
declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  public optimaze: boolean[] = [true, true, true, true];
  private streams$: Subscription[] = [];
  @ViewChildren(HomeSecondComponent) homeSecondComponent: QueryList<HomeSecondComponent>;
  @ViewChild('polygon') polygon: ElementRef;
  @ViewChild('ellipse') ellipse: ElementRef;
  // @ViewChild('circle') circle: ElementRef;
  @ViewChild('cycle1') cycle1: ElementRef;
  @ViewChild('cycle2') cycle2: ElementRef;
  @ViewChild('cycle3') cycle3: ElementRef;
  @ViewChild('cycle4') cycle4: ElementRef;
  @ViewChild('cycle5') cycle5: ElementRef;
  @ViewChild('cycle6') cycle6: ElementRef;
  @ViewChild('cycle7') cycle7: ElementRef;
  @ViewChild('cycle8') cycle8: ElementRef;
  @ViewChild('cycle9') cycle9: ElementRef;
  @ViewChild('cycle11') cycle11: ElementRef;
  @ViewChild('cycle12') cycle12: ElementRef;
  @ViewChild('cycle13') cycle13: ElementRef;
  @ViewChild('cycle14') cycle14: ElementRef;
  @ViewChild('cycle15') cycle15: ElementRef;
  @ViewChild('cycle16') cycle16: ElementRef;
  @ViewChild('cycle17') cycle17: ElementRef;
  @ViewChild('huggies_title') huggies_title: ElementRef;
  @ViewChild('cycle18') cycle18: ElementRef;
  @ViewChild('cycle19') cycle19: ElementRef;
  @ViewChild('cycle20') cycle20: ElementRef;
  @ViewChild('samsung_title') samsung_title: ElementRef;
  @ViewChild('cycle21') cycle21: ElementRef;
  @ViewChild('cycle22') cycle22: ElementRef;
  @ViewChild('cycle23') cycle23: ElementRef;
  @ViewChild('tankawhitecircle') tankawhitecircle: ElementRef;
  @ViewChild('tankatransparentcircle') tankatransparentcircle: ElementRef;


  @ViewChild('cycle24') cycle24: ElementRef;
  @ViewChild('cycle25') cycle25: ElementRef;
  @ViewChild('cycle26') cycle26: ElementRef;
  @ViewChild('cycle27') cycle27: ElementRef;
  @ViewChild('polygon2') polygon2: ElementRef;
  @ViewChild('homeFive') homeFive: ElementRef;
  @ViewChild('homPage') homPage: ElementRef;
  @ViewChild('firstparalax') firstparalax: ElementRef;
  @ViewChild('secondparalax') secondparalax: ElementRef;


  private coefSubscription: Subscription;
  private coef: number;
  private parallax;
  isMobile: boolean;
  reloadpageUser = false;

  constructor(private coefService: CoefService, private router: Router, private scrollUpDownService: ScrollUpDownService, private scrollService: ScrollService, private optimazeHome: OptimazeHome,
              meta: Meta,
              title: Title) {
    title.setTitle('Sefi Bogin - עיצוב Ux | UI אפיון ממשק משתמש | UX עיצוב ממשק משתמש | עיצוב חווית משתמש');
    meta.updateTag({
      name: 'description',
      content: 'שמי ספי בוגין מומחה, מעצב חווית משתמש Ux. מתכנן ומעצב מוצרים דיגיטלים, חי ונושם עיצוב חווית משתמש Ux עוד מ-99. מלווה לקוחות משלב פיצוח הרעיון, דרך האפיון ועד ליצירת מוצר דיגיטלי מנצח.'
    });
    meta.updateTag({
      property: 'og:title',
      content: 'Sefi Bogin - עיצוב Ux | UI אפיון ממשק משתמש | UX עיצוב ממשק משתמש | עיצוב חווית משתמש'
    });
    meta.updateTag({
      property: 'og:description',
      content: 'שמי ספי בוגין מומחה, מעצב חווית משתמש Ux. מתכנן ומעצב מוצרים דיגיטלים, חי ונושם עיצוב חווית משתמש Ux עוד מ-99. מלווה לקוחות משלב פיצוח הרעיון, דרך האפיון ועד ליצירת מוצר דיגיטלי מנצח.'
    });

    this.isMobile = this.coefService.getDeviceType();
    this.router.navigateByUrl(PAGE.HOME);
    localStorage.setItem('routUp', 'false');
    // if(this.isMobile){
    //   alert('mobile')
    // }else{
    //   alert('desctop')
    // }
    if (this.isMobile) {
      // if (localStorage.getItem('ReloadPage') != null ) {
      //   if (localStorage.getItem('ReloadPage') == 'true') {
      //     this.reloadpageUser = true;
      //   }
      //
      //   // localStorage.setItem('ReloadPage', 'false');
      // }
      let test = true;
      if (localStorage.getItem('loadPage') === 'false') {
        localStorage.setItem('loadPage', 'true');
        test = false;
        location.reload();
      }
      if (localStorage.getItem('loadPage') === 'true' && test) {
        localStorage.setItem('loadPage', 'false');
      }
    }
  }

  ngAfterViewInit() {

    this.coef = this.coefService.getCoef();
    if (!this.isMobile) {
      this.parallaxInit();
    } else {
      this.playMobileVideo();
    }

    this.coefSubscription = this.coefService.coefHasChanged.subscribe(
      (newCoef: number) => {
        this.coef = newCoef;
        if (!this.isMobile) {
          this.parallax();
          this.parallaxInit();
        }
      }
    );

    localStorage.setItem('ReloadPage', 'false');
    this.scrollToProjectSection();
    if (this.isMobile) {
      // setTimeout(()=>{
      //   // alert('start')
      //   this.playMobileVideo();
      // },3000)

    } else {
      let paralax = this.firstparalax.nativeElement;
      let paralax2 = this.secondparalax.nativeElement;
      this.optimazeHome.paralax(paralax, -1, 5800);
      this.optimazeHome.paralax(paralax2, 4000, 12800);
    }

    // this.lazyLoading();
  }

  ngOnInit() {

  }

  playMobileVideo() {
    // setTimeout(()=>{
    //   // jQuery('video').each(function () {
    //   //   let src = jQuery(this).prop('src');
    //   //   console.log(jQuery(this).prop('src'))
    //   //   console.log(jQuery(this))
    //   //   jQuery(this).attr('src' , '');
    //   //   jQuery(this).attr('src' , src);
    //   // })
    // },2000)
  }

  scrollToProjectSection() {
    setTimeout(() => {
      if (this.scrollUpDownService.homePageProjectScroll || localStorage.getItem('homePageProjectScroll') == 'true') {
        document.getElementById('triggerClick').click();
        this.scrollUpDownService.homePageProjectScroll = false;
        localStorage.setItem('homePageProjectScroll', 'false');
      }
    }, 1000);
  }


  parallaxInit() {
    const polygon = this.polygon.nativeElement;
    const ellipse = this.ellipse.nativeElement;
    // const circle = this.circle.nativeElement;
    const cycle1 = this.cycle1.nativeElement;
    const cycle2 = this.cycle2.nativeElement;
    const cycle3 = this.cycle3.nativeElement;
    const cycle4 = this.cycle4.nativeElement;
    const cycle5 = this.cycle5.nativeElement;
    const cycle6 = this.cycle6.nativeElement;
    const cycle7 = this.cycle7.nativeElement;
    const cycle8 = this.cycle8.nativeElement;
    const cycle9 = this.cycle9.nativeElement;
    const cycle11 = this.cycle11.nativeElement;
    const cycle12 = this.cycle12.nativeElement;
    const cycle13 = this.cycle13.nativeElement;
    const cycle14 = this.cycle14.nativeElement;
    const cycle15 = this.cycle15.nativeElement;
    const cycle16 = this.cycle16.nativeElement;
    const cycle17 = this.cycle17.nativeElement;
    const huggies_title = this.huggies_title.nativeElement;
    const cycle18 = this.cycle18.nativeElement;
    const cycle19 = this.cycle19.nativeElement;
    const cycle20 = this.cycle20.nativeElement;
    const samsung_title = this.samsung_title.nativeElement;
    const cycle21 = this.cycle21.nativeElement;
    const cycle22 = this.cycle22.nativeElement;
    const cycle23 = this.cycle23.nativeElement;
    const tankatransparentcircle = this.tankatransparentcircle.nativeElement;
    const tankawhitecircle = this.tankawhitecircle.nativeElement;
    const cycle24 = this.cycle24.nativeElement;
    const cycle25 = this.cycle25.nativeElement;
    const cycle26 = this.cycle26.nativeElement;
    const cycle27 = this.cycle27.nativeElement;
    const polygon2 = this.polygon2.nativeElement;

    this.parallax = parallaxer([{
      element: polygon,
      distance: 0.7,
      offset: 1600 * this.coef
    },
      {
        element: ellipse,
        distance: 0.9,
        offset: 920 * this.coef
      },
      // {
      //   element: circle,
      //   distance: 0.8,
      //   offset: 2260 * this.coef
      // }
      // ,
      {
        element: cycle1,
        distance: 0.7,
        offset: 6500 * this.coef,
      }, {
        element: cycle2,
        distance: 0.7,
        offset: 6500 * this.coef,
      }, {
        element: cycle3,
        distance: 0.7,
        offset: 7150 * this.coef,
      }, {
        element: cycle4,
        distance: 0.9,
        offset: 4800 * this.coef,
      }, {
        element: cycle5,
        distance: 0.45,
        offset: 11150 * this.coef,
      }, {
        element: cycle6,
        distance: 0.8,
        offset: 6900 * this.coef,
      }, {
        element: cycle7,
        distance: 0.6,
        offset: 9600 * this.coef,
      }, {
        element: cycle8,
        distance: 0.5,
        offset: 11800 * this.coef,
      }, {
        element: cycle9,
        distance: 0.5,
        offset: 12200 * this.coef,
      }, {
        element: cycle11,
        distance: 0.8,
        offset: 10000 * this.coef,
      }, {
        element: cycle12,
        distance: 0.8,
        offset: 10010 * this.coef,
      }, {
        element: cycle13,
        distance: 0.7,
        offset: 9900 * this.coef,
      }, {
        element: cycle14,
        distance: 0.8,
        offset: 9500 * this.coef,
      }, {
        element: cycle15,
        distance: 0.8,
        offset: 8700 * this.coef,
      }, {
        element: cycle16,
        distance: 0.6,
        offset: 14250 * this.coef,
      }, {
        element: cycle17,
        distance: 0.6,
        offset: 16000 * this.coef,
      }
      // , {
      //   element: huggies_title,
      //   distance: 1,
      //   offset: 11740 * this.coef,
      // }
      , {
        element: cycle18,
        distance: 0.8,
        offset: 12350 * this.coef,
      }, {
        element: cycle19,
        distance: 0.6,
        offset: 18600 * this.coef,
      }, {
        element: cycle20,
        distance: 0.7,
        offset: 15000 * this.coef,
      }
      // ,
      // {
      //   element: samsung_title,
      //   distance: 1,
      //   offset: 13350 * this.coef,
      // }
      , {
        element: cycle21,
        distance: 0.5,
        offset: 21100 * this.coef,
      }, {
        element: cycle22,
        distance: 0.7,
        offset: 15700 * this.coef,
      }, {
        element: tankawhitecircle,
        distance: 0.7,
        offset: 16600 * this.coef,
      },{
        element: tankatransparentcircle,
        distance: 0.7,
        offset: 17800 * this.coef,
      }, {
        element: cycle23,
        distance: 0.7,
        offset: 18000 * this.coef,
      }, {
        element: cycle24,
        distance: 0.5,
        offset: 27000 * this.coef,
      }, {
        element: cycle25,
        distance: 0.7,
        offset: 21450 * this.coef,
      }, {
        element: cycle26,
        distance: 0.5,
        offset: 29700 * this.coef,
      }, {
        element: cycle27,
        distance: 0.6,
        offset: 24500 * this.coef,
      }, {
        element: polygon2,
        distance: 0.7,
        offset: 20500 * this.coef,
      }]);
  }

  ngOnDestroy() {
    this.coefSubscription.unsubscribe();
  }

}
