import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PAGE} from '../router.path';
import {ScrollService} from './scroll.service';

@Injectable()
export class PageService {

  constructor(private router: Router , private scrollService: ScrollService) {
  }

  goToTanka(){
    this.scrollService.animationTOpDowan = false;
    this.router.navigateByUrl(PAGE.TANKA);
    document.documentElement.style.overflowY = 'scroll';
  }

  goToFloat(){
    this.scrollService.animationTOpDowan = false;
    this.router.navigateByUrl(PAGE.FLOAT);
    document.documentElement.style.overflowY = 'scroll';
  }

  goTempo() {
    this.scrollService.animationTOpDowan = false;
    this.router.navigateByUrl(PAGE.TEMPO_PAGE);
    document.documentElement.style.overflowY = 'scroll';
  }

  goWalla() {
    this.scrollService.animationTOpDowan = false;
    this.router.navigateByUrl(PAGE.WALLA_PAGE);
    document.documentElement.style.overflowY = 'scroll';
  }

  goHome() {
    this.scrollService.animationTOpDowan = false;
    setTimeout(()=> {
      this.router.navigateByUrl(PAGE.HOME);
    },1);
    localStorage.setItem('ReloadPage', 'true');
    document.documentElement.style.overflowY = 'scroll';
  }

  goHuggies() {
    this.scrollService.animationTOpDowan = false;
    setTimeout(()=>{
      this.router.navigateByUrl(PAGE.HUGGIES_PAGE);
    },1)

    document.documentElement.style.overflowY = 'scroll';
  }

  goAbout() {
    this.scrollService.animationTOpDowan = false;
    setTimeout(()=>{
      this.router.navigateByUrl(PAGE.ABOUT_PAGE);
    },1);
    document.documentElement.style.overflowY = 'scroll';
  }

  goContact() {

    this.scrollService.animationTOpDowan = false;
    setTimeout(()=>{
      this.router.navigateByUrl(PAGE.CONTACT_PAGE);
    },1);
    document.documentElement.style.overflowY = 'scroll';
  }

  goFiat() {
    this.scrollService.animationTOpDowan = false;
    setTimeout(()=>{
      this.router.navigateByUrl(PAGE.FIAT_PAGE);
    },1);
    document.documentElement.style.overflowY = 'scroll';
  }

  goSamsung() {
    this.scrollService.animationTOpDowan = false;
    setTimeout(()=>{
      this.router.navigateByUrl(PAGE.SAMSUNG_PAGE);
    },1);
    document.documentElement.style.overflowY = 'scroll';
  }

  goYuval() {
    this.scrollService.animationTOpDowan = false;
    setTimeout(()=>{
      this.router.navigateByUrl(PAGE.YUVAL_PAGE);
    },1);
    document.documentElement.style.overflowY = 'scroll';
  }

}
