<div *ngIf="show">
  <div [style.background-color]="color" class="block" *ngIf="blockIsShown" @fadeOut>
    <div  class="wrap_loading" *ngIf="start">
      <!--<img class="git_loading" [src]="isMobile ? '/assets/gif/sefi-loading.gif' : ''" alt="">-->
      <span *ngIf="portfolio" >
        <div class="git_loading center" *ngIf="isMobile">
          <div class="spinner">
            <div class="dot1"></div>
            <div class="dot2"></div>
          </div>
        </div>
        <p *ngIf="isMobile" class="text"  >
          כמה שניות
  והפרויקט <br> יוצא

  מהתנור
        </p>
      </span>
    </div>
  </div>

</div>
