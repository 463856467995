import {
  AfterViewInit,
  Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewChild,
  ViewRef
} from '@angular/core';
import {TEXTS} from '../../common/texts';
import {BULB, FADE_IN_OUT, PORTFOLIO_UP} from '../../common/animations';
import {PageService} from '../../services/page.service';
import {Router} from '@angular/router';
import {ScrollService} from '../../services/scroll.service';
import {Subscription} from 'rxjs/Subscription';
import {CoefService} from '../../services/coef.service';
import {ScrollUpDownService} from '../../services/scrollUpDown.service';
import set = Reflect.set;

declare var jQuery: any;

@Component({
  selector: 'app-footer-portfolio',
  templateUrl: './footer-portfolio.component.html',
  styleUrls: ['./footer-portfolio.component.scss'],
  animations: [FADE_IN_OUT, BULB, PORTFOLIO_UP]
})
export class FooterPortfolioComponent implements OnInit, OnDestroy, AfterViewInit {
  coef;
  subscription;

  @Input() style;
  @Output() paralaxStop = new EventEmitter();
  animationUpRout = false;
  animationState = 'hidden';
  menuIsShown = false;
  scrollTOP = false;
  heightPage;
  routAnimate = false;
  isMobile = false;
  test = true;
  routTest = true;
  magnit = false;
  can_move = false;
  top_magnit = false;
  top_can_move = false;
  animationTOpDowan: any;
  private streams$: Subscription[] = [];
  private scrollPosition: number;
  videoHtml;
  reloadpageUser = false;

  constructor(private pageService: PageService,
              private router: Router,
              private scrollService: ScrollService,
              private coefService: CoefService,
              private scrollUpDownService: ScrollUpDownService) {
    this.isMobile = this.coefService.getDeviceType();
    if (this.isMobile) {
      if (localStorage.getItem('ReloadPage') != null) {
        if (localStorage.getItem('ReloadPage') == 'true') {
          this.reloadpageUser = true;
          // console.log('reload page js');
        }
        // localStorage.setItem('ReloadPage', 'false');
      }
      let test = true;
      if (localStorage.getItem('loadPage') == 'false') {
        localStorage.setItem('loadPage', 'true');
        test = false;
        location.reload();
      }
      if (localStorage.getItem('loadPage') == 'true' && test) {
        localStorage.setItem('loadPage', 'false');
      }
      this.animationTOpDowan = JSON.parse(localStorage.getItem('routUp'));
    }
  }

  @ViewChild('videoStartsLast') videoStartsFirst: ElementRef;

  readonly TEXT = TEXTS;

  ngAfterViewInit() {
    this.playVideo();
    this.stopMagnit();
    if (this.isMobile) {

      window.addEventListener('load', () => {
        localStorage.setItem('ReloadPage', 'false');

        setTimeout(() => {
          // console.log();
          if (this.reloadpageUser) {
            if (this.animationTOpDowan) {
              this.stopMagnit();
              window.scrollTo(0, document.documentElement.scrollHeight - window.innerHeight - 1);
              this.stopMagnit();
            } else {
              this.stopMagnit();
              window.scroll(0, 1);
              this.top_magnit = true;
              this.stopMagnit();
            }
            this.stopMagnit();
          } else {
            this.stopMagnit();
            window.scroll(0, 1);
            this.top_magnit = true;
            this.stopMagnit();
          }
        }, 300);
      });
    } else {
      setTimeout(() => {
        if (this.scrollUpDownService.homePageProjectScrolls) {
          this.stopMagnit();
          window.scrollTo(0, document.documentElement.scrollHeight - window.innerHeight - 1);
          this.stopMagnit();
        } else {
          this.stopMagnit();
          window.scroll(0, 1);
          this.stopMagnit();
        }
        this.stopMagnit();
      }, 2250);
    }
  }


  playVideo() {
    let videoStartsLast;
    if (!this.isMobile && !(this.style.posterRelativePathPC || this.style.posterRelativePathMobile)) {
      videoStartsLast = this.videoStartsFirst.nativeElement;
      videoStartsLast.pause();

    }
    this.coef = this.coefService.getCoef();
    this.streams$.push(this.coefService.coefHasChanged.subscribe(
      (newCoef: number) => {
        this.coef = newCoef;
      }
    ));



    this.streams$.push(this.scrollService.scrollPositionHasChanged.subscribe(
      (newScrollPosition: number) => {

        if (!this.isMobile && !(this.style.posterRelativePathPC || this.style.posterRelativePathMobile)) {
          if (1 * this.coef < newScrollPosition && 3000 * this.coef || 0 * this.coef < newScrollPosition && 3000 * this.coef > newScrollPosition) {
            videoStartsLast.play();
          } else {
            videoStartsLast.pause();
          }
        }
      }
    ));
  }


  stopMagnit() {
    this.magnit = false;
    this.can_move = false;
    this.top_magnit = false;
    this.top_can_move = false;
  }

  bottomreload;
  bottomreloadTop = true;
  touchStart;
  stratroutind = false;

  ngOnInit() {
    this.coef = this.coefService.getCoef();
    this.subscription = this.coefService.coefHasChanged.subscribe(
      (newCoef: number) => {
        this.coef = newCoef;
      });

    if (!this.isMobile) {
      this.stratroutind = true;
    }
    document.addEventListener('touchstart', (e) => {
      if (!this.stratroutind) {
        this.stratroutind = true;
      }
    });
    if (this.isMobile) {
      window.addEventListener('load', () => {
        setTimeout(() => {
          jQuery('video').each(function () {
            // console.log(jQuery(this).data('url'));
            jQuery(this).attr('src', jQuery(this).data('url'));
          });
        }, 300);
      });
    }
    this.videoHtml = this.coefService.videoHtml('', 'footerUrlVideo', `poster="/assets/img/sefiPoster/poster/${this.style.posterMob}"`, this.style.urlVimeoMob);
    setTimeout(() => {
      if (!this.isMobile) {
        if (this.scrollUpDownService.homePageProjectScrolls) {
          this.routAnimate = true;
          this.animationState = 'shown';
          this.bottomreload = true;
        }
      } else {
        if (this.animationTOpDowan) {
          this.routAnimate = true;
          this.animationState = 'shown';
          this.bottomreload = true;
        }
      }
      if (this.scrollService.firstload) {
        this.scrollTOP = true;
      }
      if (!this.scrollService.firstload) {
        this.scrollService.firstload = true;
      }

      this.streams$.push(this.scrollService.scrollPositionHasChanged.subscribe(
        (newScrollPosition: number) => {

          this.scrollPosition = newScrollPosition;
          this.heightPage = document.documentElement.scrollHeight - window.innerHeight;
          // rout top start
          if (Math.ceil(this.scrollPosition) > 1) {
            this.top_magnit = false;
            this.top_can_move = false;
            this.scrollTOP = false;
            this.bottomreloadTop = false;
          }
          if (Math.ceil(this.scrollPosition) == 0) {
            window.scroll(0, 1);
            this.top_magnit = true;
          }
          if (Math.ceil(this.scrollPosition) == 1 && this.top_magnit) {
            setTimeout(() => {
              this.scrollTOP = true;
            }, 1000);
          }
          if (this.scrollTOP && Math.ceil(this.scrollPosition) == 0) {
            this.animationTOpDowan = true;
            this.scrollUpDownService.homePageProjectScrolls = true;
            localStorage.setItem('routUp', 'true');
            this.routUp();
          }
          if (this.isMobile) {
            // document.addEventListener("touchstart", (e)=> {
            //   this.touchStart = e.changedTouches[0]['clientY'];
            // }, false);
            // document.addEventListener("touchend", (e)=> {
            //   console.log(e.changedTouches[0]['clientY'])
            //   if(e.changedTouches[0]['clientY'] < this.touchStart) {
            //     this.bottomreloadTop = false;
            //   }
            //   if(e.changedTouches[0]['clientY'] > this.touchStart && this.bottomreloadTop) {
            //     this.animationTOpDowan = true;
            //     this.scrollUpDownService.homePageProjectScrolls = true;
            //     localStorage.setItem('routUp' ,'true');
            //     this.routUp();
            //   }
            // }, false);
          }

          // rout top and
          // routing bottom start
          if (Math.ceil(this.scrollPosition) < this.heightPage - 1) {
            this.magnit = false;
            this.can_move = false;
            this.routAnimate = false;
            this.bottomreload = false;
          }
          if (Math.ceil(this.scrollPosition) >= this.heightPage) {
            window.scroll(0, this.heightPage - 1);
            this.magnit = true;
            this.animationState = 'shown';
          }
          if (Math.ceil(this.scrollPosition) >= this.heightPage && this.magnit) {
            setTimeout(() => {
              this.routAnimate = true;
            }, 1000);
          }
          if (Math.ceil(this.scrollPosition) >= this.heightPage && this.routAnimate) {
            this.animationTOpDowan = false;
            this.scrollUpDownService.homePageProjectScrolls = false;
            localStorage.setItem('routUp', 'false');
            this.menuIsShown = true;
            this.routDown();
          }
        }
      ));
      if (this.isMobile) {
        document.addEventListener('touchstart', (e) => {
          this.touchStart = e.changedTouches[0]['clientY'];
        }, false);
        document.addEventListener('touchend', (e) => {
          if (e.changedTouches[0]['clientY'] > this.touchStart) {
            this.bottomreload = false;
          }
          if (e.changedTouches[0]['clientY'] < this.touchStart && this.bottomreload) {
            this.animationTOpDowan = false;
            this.scrollUpDownService.homePageProjectScrolls = false;
            localStorage.setItem('routUp', 'false');
            this.menuIsShown = true;
            this.routDown();
          }
        }, false);
      }
    }, 2500);
// bottom routing and
  }

  contactUs() {
    this.pageService.goContact();
  }

  routDown() {
    localStorage.setItem('ReloadPage', 'true');
    if (this.stratroutind) {
      this.animationTOpDowan = false;
      this.scrollUpDownService.homePageProjectScrolls = false;
      localStorage.setItem('routUp', 'false');
      if (!this.isMobile) {
        this.paralaxStop.emit();
      }
      setTimeout(() => {
        document.documentElement.style.overflowY = 'scroll';
        this.router.navigateByUrl(this.style.routDown);
      }, 500);
      if (!this.isMobile) {
        this.paralaxStop.emit();
      }
    }
  }

  routUp() {
    localStorage.setItem('ReloadPage', 'true');
    // console.log(localStorage.getItem('ReloadPage'));
    if (this.stratroutind) {
      if (!this.isMobile) {
        this.paralaxStop.emit();
      }
      this.animationUpRout = true;
      setTimeout(() => {
        document.documentElement.style.overflowY = 'scroll';
        this.router.navigateByUrl(this.style.routUp);
      }, 700);
      if (!this.isMobile) {
        this.paralaxStop.emit();
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    for (const stream$ of this.streams$) {
      stream$.unsubscribe();
    }
  }
}
