import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TEXTS} from '../../../common/texts';
import {PageService} from '../../../services/page.service';
import {CoefService} from '../../../services/coef.service';
import {ScrollUpDownService} from '../../../services/scrollUpDown.service';
import {OptimazeHome} from '../../../services/optimazeHome';
import { DomSanitizer } from '@angular/platform-browser';
declare var jQuery:any;
@Component({
  selector: 'app-home-nine',
  templateUrl: './home-nine.component.html',
  styleUrls: ['./home-nine.component.scss']
})
export class HomeNineComponent implements OnInit, OnDestroy, AfterViewInit  {
  readonly TEXT = TEXTS;
  isMobile: boolean;
  videoHtml;
  @ViewChild('videoPlayer') videoPlayer: any;


  constructor(private coefService: CoefService,
              private pageService: PageService ,
              private scrollUpDownService : ScrollUpDownService ,
              private optimazeHome: OptimazeHome ,
              private domSanitizer: DomSanitizer) {
    this.isMobile = this.coefService.getDeviceType();
  }

  goHuggies() {
    this.pageService.goHuggies();
  }

  ngOnInit() {
    // this.videoHtml = this.domSanitizer.bypassSecurityTrustHtml(
    //   `<video poster="/assets/img/home/Screenshot_2.png" style="width: 100%;" playsinline loop muted autoplay>
    //       <source src="https://player.vimeo.com/external/258417502.sd.mp4?s=3b10b3f81b621f71f45e8cd6f8c1b13139034235&profile_id=165" type="video/mp4">No HTML5 supported.</source>
    //    </video>`
    // );
    setTimeout(() => {
      // jQuery('#videoSrc').src('https://player.vimeo.com/external/258416862.sd.mp4?s=e499c88ce42c57aaeeed7862db254908c0855934&profile_id=165');
      jQuery('#videoLoad4').attr('src', 'https://player.vimeo.com/external/258417502.sd.mp4?s=3b10b3f81b621f71f45e8cd6f8c1b13139034235&profile_id=165');
    }, 100);
    this.videoHtml =  this.coefService.videoHtml('', 'videoLoad4', `poster="/assets/img/home/Screenshot_2.png"` );
    // this.coefService.videoHtml('https://player.vimeo.com/external/258417502.sd.mp4?s=3b10b3f81b621f71f45e8cd6f8c1b13139034235&profile_id=165', '', `poster="/assets/img/home/Screenshot_2.png"` );
  }
  ngAfterViewInit() {
    // this.videoHtml =  this.coefService.videoHtml('https://player.vimeo.com/external/258417502.sd.mp4?s=3b10b3f81b621f71f45e8cd6f8c1b13139034235&profile_id=165', '', `poster="/assets/img/home/Screenshot_2.png"` );
    if (!this.isMobile) {
      let video = this.videoPlayer.nativeElement;
      video.pause();
      this.optimazeHome.stopVideo(video , 8900 , 10000);
    }
  }
  ngOnDestroy() {
    this.optimazeHome.unSuscribe();
  }


}
