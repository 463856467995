import { Component, OnInit } from '@angular/core';
import {TEXTS} from '../../../common/texts';
import {CoefService} from '../../../services/coef.service';

@Component({
  selector: 'app-home-eleven',
  templateUrl: './home-eleven.component.html',
  styleUrls: ['./home-eleven.component.scss']
})
export class HomeElevenComponent implements OnInit {
  readonly TEXT = TEXTS;
  isMobile: boolean;

  constructor(private coefService: CoefService) {
    this.isMobile = this.coefService.getDeviceType();
  }

  ngOnInit() {
  }

}
