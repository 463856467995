<div (click)="goFiat()" style="cursor: pointer" class="fiat">
  <img class="background" [src]="!isMobile ? '/assets/img/home/BgFiatSection.png' : '/assets/img/home/Mobile/bg_fiat.png'" alt="">
  <div class="wraper_container">
    <div class="col2">
      <img class="illustration" [src]="!isMobile ? '/assets/img/home/ilustrationFiat.png' : '/assets/img/home/Mobile/fiat_chair.png'" alt="">
    </div>
    <div class="col1">
      <!--<p class="titleBig" *ngIf="!isMobile" [@scrolledState]="animationState">SEFI BOGIN</p>-->
      <p class="titleBig" *ngIf="!isMobile" >SEFI BOGIN</p>
      <div>
        <img [src]="!isMobile ? '/assets/img/elements/fiat_whote.png' : '/assets/img/home/Mobile/logo_fiat.png'" alt="">
        <p class="description"> {{TEXT.textFiatHome}}</p>
        <p class="hashtag hashtagHome" [innerText]="!isMobile ? TEXT.hashtag : TEXT.hashtag2" ></p>
        <div class="wrapper_btn">
          <a class="btn styleBtnHome"(click)="goFiat()">{{TEXT.btnWalla}}</a></div>
      </div>
    </div>
  </div>
</div>


