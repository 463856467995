<div [style.background-color]="style.ColorAnimationUp" [ngClass]="{'animationUpRout':animationUpRout}"></div>
<div class="fullScreen" *ngIf="menuIsShown" @easeInOut [style.background-color]="style.ColorAnimation"></div>
<section>
  <!--<video  playsinline loop muted autoplay [src]=" isMobile ? 'https://player.vimeo.com/external/258421665.hd.mp4?s=1d29995452d3b079bb6e24113baf3e10d0da3981&profile_id=174' :  'https://player.vimeo.com/external/258416638.hd.mp4?s=d81221fb09e303014408597ca95fa117ae168fe2&profile_id=175'" ></video>-->

  <ng-container *ngIf="style.posterRelativePathPC || style.posterRelativePathMobile">

    <img [src]="isMobile? style.posterRelativePathMobile:style.posterRelativePathPC" style="width: 100%;"/>

  </ng-container>

  <ng-container *ngIf="!(style.posterRelativePathPC || style.posterRelativePathMobile)">


    <video
      poster="{{'/assets/img/sefiPoster/poster/' + style.posterDesctop}}"
      *ngIf="!isMobile" playsinline loop muted autoplay [src]="style.urlVideoVimeo" #videoStartsLast></video>


    <div *ngIf="isMobile" [innerHtml]="videoHtml"></div>

  </ng-container>


  <div class="wrapText">
    <img class="snake" *ngIf="style.urlImgSnake && isMobile"
         src="/assets/img/{{style.urlImgSnake}}" alt="">
    <h4 *ngIf="!isMobile " class="titleFooterVideo" [style.color]="style.colorText"
        [style.paddingTop]="style.marginTopDesctop">אוהבים לשחות עם דולפינים?</h4>
    <h4 *ngIf="isMobile " class="titleFooterVideo" [style.color]="style.colorText" [style.font-size]="style.fontSize"
        [style.paddingTop]="style.marginTop">אוהבים לשחות עם דולפינים?</h4>
    <div class="wrapBtn">
      <a class="btnText" [style.background-color]="style.colorButton" [style.border-color]="style.borderColor"
         [style.color]="style.colorTextButton" (click)="contactUs()">צרו קשר</a>
    </div>
  </div>


  <div [@scrolledState]="animationState">
    <img class="bg_footer"
         [src]="isMobile ? '/assets/img/' + style.urlBgBottomMobile : '/assets/img/'+ style.urlBgBottom"
         alt="">
    <div class="work_next_mouse">
      <div class="mouse" (click)="menuIsShown = true" (click)="routDown()">
        <!--<div *ngIf="menuIsShown" @bulb class="bulb "   [style.background-color]="style.ColorAnimation"></div>-->
        <div *ngIf="menuIsShown" class="start" [ngClass]="{'bublAnimation': menuIsShown}"
             [style.background-color]="style.ColorAnimation"></div>
        <div class="cane"></div>
      </div>
      <!--<div *ngIf="isMobile" class="wrapper_arrow">-->
      <!--<div *ngIf="menuIsShown" @bulb class="bulb" [ngClass]="{'bulb': menuIsShown}" [style.background-color]="style.ColorAnimation"></div>-->
      <!--<img src="/assets/img/tempo/mob/arrow.png" (click)="menuIsShown = true" (click)="routDown()" alt="">-->
      <!--</div>-->
      <p *ngIf="style.logoText" class="textDown">{{style.logoText}}</p>
      <img *ngIf="!style.logoText && !style.widthLogo" [style.width]="style.widthLogo"
           src="/assets/img/{{style.urlLogo}}" class="logo" alt="">
      <img *ngIf="!style.logoText && style.widthLogo" [style.width]="style.widthLogo"
           src="/assets/img/{{style.urlLogo}}" class="logo" alt="">
    </div>
  </div>
</section>

