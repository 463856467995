<section>
  <img [src]="!isMobile ? '/assets/img/home/iilustrationAndBg.png' : '/assets/img/home/Mobile/bg_20years_new_2.png'" alt="">
  <div class="wraper_container">
    <div class="col2">
    </div>
    <div class="col1">
      <div>
        <p class="titleBig">{{TEXT.titleBigSefi}}</p>
        <p class="titleLitle">{{TEXT.titleLitleSefi}}</p>
        <p class="description"> {{!isMobile ? TEXT.textSefiHome : TEXT.textSefiHomeMobile}}</p>
      </div>
    </div>
  </div>
</section>
