import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(meta: Meta,
              title: Title) {
    title.setTitle('ספי בוגין מעצב חווית משתמש UX | עיצוב חווית משתמש UX | Sefi Bogin');
    meta.updateTag({ name: 'description', content: 'שמי ספי בוגין מומחה, מעצב חווית משתמש Ux. מתכנן ומעצב מוצרים דיגיטלים, חי ונושם עיצוב חווית משתמש Ux עוד מ-99. מלווה לקוחות משלב פיצוח הרעיון, דרך האפיון ועד ליצירת מוצר דיגיטלי מנצח. '});
    meta.updateTag({property: 'og:title', content: 'ספי בוגין מעצב חווית משתמש UX | עיצוב חווית משתמש UX | Sefi Bogin'});
    meta.updateTag({property: 'og:description', content: 'שמי ספי בוגין מומחה, מעצב חווית משתמש Ux. מתכנן ומעצב מוצרים דיגיטלים, חי ונושם עיצוב חווית משתמש Ux עוד מ-99. מלווה לקוחות משלב פיצוח הרעיון, דרך האפיון ועד ליצירת מוצר דיגיטלי מנצח.'});
  }

  ngOnInit() {
  }

}
