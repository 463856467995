<section class="home_second" #section>
  <!--<video id="video_loop" class="video_repeater" style="opacity: 0" #video src="{{currentVideo}}" on-playing="onVidStarted()" playsinline loop autoplay muted></video>-->
  <!--<video *ngIf="isMobile" id="videoLoad" class="video_repeater"    src="https://player.vimeo.com/external/259667669.hd.mp4?s=4de19ef693bf9867087076bb65a2fee33e97789d&profile_id=174" playsinline loop autoplay muted></video>-->
  <video  poster="/assets/img/home/seconPoster2.jpg" id="videoLoad" *ngIf="!isMobile" class="video_repeater"
         [src]="!isMobile ?  'https://player.vimeo.com/external/259667947.hd.mp4?s=927543821f5d062447f240b888811f3606526c6d&profile_id=174': 'https://player.vimeo.com/external/259667669.hd.mp4?s=4de19ef693bf9867087076bb65a2fee33e97789d&profile_id=174'"
         playsinline  loop autoplay muted #videoStartsFirst></video>
  <div *ngIf="isMobile" [innerHtml]="videoHtml"></div>
  <div class="info_project">
    <img class="snake" src="/assets/img/home/Mobile/snake.png" alt="" *ngIf="isMobile">
    <div class="wrapper">
      <div *ngIf="!isMobile" style="opacity: 0" class="wrapper_img" #info>
        <img class="video_repeater" id="changeSrc" [ngStyle]="{'width':styleWidth}" src="{{current_logo_src_full}}"
             alt="" (change)="update($event)">
      </div>
      <div *ngIf="isMobile" style="" class="wrapper_img" #info>
        <img id="widthImg" class="textWraperHome" [ngStyle]="{'width':styleWidth}" src="{{current_logo_src_full}}"
             alt="">
      </div>
    </div>
    <!--<p *ngIf="!isMobile" class="info" >{{TEXT2.textAll}}</p>-->
    <p *ngIf="!isMobile" class="info">
      אחד אחד, כל פרוייקט בובה.
      כנסו,
      <br>
      אם זה מצית בכם כיוון לפרוייקט <br> שיושב על שולחנכם - ת׳קשרו.
    </p>
    <p *ngIf="isMobile" class="info" [innerText]="TEXT2.textAllMob"></p>
    <div class="wrapBtn">
      <a class="btn" scrollTo scrollTargetSelector="#toThisElement">{{TEXT.btnTextProjectHome}}</a>
    </div>
  </div>
</section>


<!--<ng-parallax-->
<!--style="height: 800px;-->
<!--width: 430px;-->
<!--background-color: transparent;-->
<!--position: absolute;-->
<!--top: 0;-->
<!--left: 0;-->
<!--"img="/assets/img/elements/Ellipse3.png" [config]="someConfig" class="some-class"></ng-parallax>-->




