<app-fade-in-animation [portfolio]="false" *ngIf="isMobile" [color]="'#a5e0f2'"
                       [reload]="false"></app-fade-in-animation>
<!--ffffff-->
<div #homPage>
  <app-main-menu [fadeInOn]="1800" [stickOn]="2150" [stickOnMob]="7810"
                 (projectSection)="scrollToProjectSection()"></app-main-menu>
  <app-home-first></app-home-first>
  <app-home-second></app-home-second>
  <app-home-third></app-home-third>
  <app-my-way></app-my-way>
</div>
<app-home-five id="toThisElement" #homeFive></app-home-five>
<app-home-sixth></app-home-sixth>
<app-home-seventh></app-home-seventh>
<app-home-eighth></app-home-eighth>
<app-home-nine></app-home-nine>
<app-home-ten></app-home-ten>
<app-home-tanka></app-home-tanka>
<app-home-float></app-home-float>
<app-home-eleven></app-home-eleven>
<app-home-twelfth></app-home-twelfth>


<div class="wrapper_ellipse_3">
  <img class="ellipse_3"
       [src]="!isMobile ? '/assets/img/elements/Ellipse3.png' : '/assets/img/home/Mobile/Parallax/1.png'"
       #ellipse>
</div>
<!--circle home section walla start-->
<div #firstparalax>


  <img class="polygon1"
       [src]="!isMobile ? '/assets/img/elements/Polygon1.png' : '/assets/img/home/Mobile/Parallax/2.png'"
       alt="" #polygon>
  <img class="circle"
       [src]="!isMobile ? '/assets/img/elements/cycle-rught-down-ipad.png' : '/assets/img/home/Mobile/Parallax/3.png'"
       alt="" #circle>
  <img class="cycle1" #cycle1
       [src]="!isMobile ? '/assets/img/elements/cycle-rught8.png' : '/assets/img/home/Mobile/Parallax/4.png'"
       alt="">
  <img class="cycle2" #cycle2
       [src]="!isMobile ? '/assets/img/elements/EllipseTransparentcopy.png' : '/assets/img/home/Mobile/Parallax/5.png'"
       alt="">
  <img class="cycle3" #cycle3
       [src]="!isMobile ? '/assets/img/elements/Ellipse3litle.png' : '/assets/img/home/Mobile/Parallax/6.png'"
       alt="">
  <img class="cycle4" #cycle4
       [src]="!isMobile ? '/assets/img/elements/EllipseTransparent2copy.png' : '/assets/img/home/Mobile/Parallax/7.png'"
       alt="">

</div>

<div #secondparalax>


  <img class="cycle5" #cycle5
       [src]="!isMobile ? '/assets/img/elements/Ellipse_shadov1.png' : '/assets/img/home/Mobile/Parallax/8.png'"
       alt="">
  <!--circle home section walla end-->

  <!--circle home section tempo start-->
  <img class="cycle6" #cycle6
       [src]="!isMobile ? '/assets/img/elements/EllipseTransparent2copy.png' : '/assets/img/home/Mobile/Parallax/9.png'"
       alt="">

  <img class="cycle7" #cycle7
       [src]="!isMobile ? '/assets/img/elements/elipseShadonv2.png' : '/assets/img/home/Mobile/Parallax/10.png'"
       alt="">
  <img class="cycle8" #cycle8
       [src]="!isMobile ? '/assets/img/elements/EllipseTransparentcopy.png' : '/assets/img/home/Mobile/Parallax/11.png'"
       alt="">

  <img class="cycle9" #cycle9
       [src]="!isMobile ? '/assets/img/elements/Ellipse_white_shadowV3.png' : '/assets/img/home/Mobile/Parallax/12.png'"
       alt="">
  <!--circle home section tempo end-->

  <!--circle home section Yuval start-->
  <img class="cycle13" #cycle13
       [src]="!isMobile ? '/assets/img/elements/elipse_yuval.png' : '/assets/img/home/Mobile/Parallax/13.png'">
  <img class="cycle14" #cycle14
       [src]="!isMobile ? '/assets/img/elements/EllipseTransparentcopy.png' : '/assets/img/home/Mobile/Parallax/14.png'"
       alt="">
  <!--circle home section Yuval end-->
  <!--circle home section Fiat start-->
  <img class="cycle11" #cycle11
       [src]="!isMobile ? '/assets/img/elements/EllipseTransparent2copy.png' : '/assets/img/home/Mobile/Parallax/15.png'"
       alt="">
  <img class="cycle12" #cycle12
       [src]="!isMobile ? '/assets/img/elements/Ellipse_pink.png' : '/assets/img/home/Mobile/Parallax/16.png'"
       alt="">
  <img class="cycle16" #cycle16
       [src]="!isMobile ? '/assets/img/elements/elipseShadonv2.png' : '/assets/img/home/Mobile/Parallax/17.png'"
       alt="">
  <!--circle home section Fiat end-->
</div>
<div class="removeOnMobile">
  <img class="cycle15" #cycle15
       src="/assets/img/elements/EllipseTransparent2copy.png" alt="">
  <!--circle home section Huggies start-->
  <img class="cycle17" #cycle17 src='/assets/img/elements/cycle_huggies.png'
       alt="">
  <p class="titleBig huggies_title" #huggies_title>SEFI BOGIN</p>
  <img class="cycle18" #cycle18
       src="/assets/img/elements/Ellipse_white_shadowV3.png" alt="">
  <!--circle home section Huggies end-->

  <!--circle home section Samsung start-->
  <img class="cycle19" #cycle19 src="/assets/img/elements/elipse_samsung.png"
       alt="">
  <img class="cycle20" #cycle20
       src="/assets/img/elements/EllipseTransparent2copy.png" alt="">
  <p class="titleBig samsung_title" #samsung_title>SEFI BOGIN</p>
  <img class="cycle21" #cycle21
       src="/assets/img/elements/EllipseTransparentcopy.png" alt="">
  <img class="cycle22" #cycle22
       src="/assets/img/elements/elipse_samsung2.png" alt="">
  <!--circle home section Samsung end-->
  <img class="tankatransparentcircle" #tankatransparentcircle src="../../../assets/img/home/Tanka/transparent-circle.png" alt="">
  <img class="tankawhitecircle" #tankawhitecircle src="../../../assets/img/home/Tanka/white-circle.png" alt="">
  <img class="cycle23" #cycle23  src="../../../assets/img/home/Tanka/yellow-circle.png" alt="">
  <img class="cycle24" #cycle24 src="/assets/img/home/cycle24.png" alt="">


  <!--footer parallax-->

  <img src="/assets/img/elements/cicle11.png" class="cycle25" #cycle25
       alt="">
  <img src="/assets/img/elements/cicle12.png" class="cycle26" #cycle26
       alt="">
  <img src="/assets/img/elements/cycle13.png" class="cycle27" #cycle27
       alt="">
  <img class="polygon2" src="/assets/img/elements/Polygon1.png" alt=""
       #polygon2>

</div>


<div class="tankaMobileWhiteParralex"></div>
<div class="tankaMobileYellowParralex"></div>
<div class="tankaMobileSmallCircleParralex"></div>



<!--<app-home-second [color]="'green'">
  <app-home-third></app-home-third>
</app-home-second>-->

<!--
<app-home-second [color]="'blue'">
 <div [innerText]="TEXT.myText"></div>
</app-home-second>
-->

<!--<img src="/assets/img/home/1250833953592952166.png" alt="">-->


