import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TEXTS} from '../../../common/texts';
import {BULB, FADE_IN_OUT} from '../../../common/animations';
import {CoefService} from '../../../services/coef.service';
import {OptimazeHome} from '../../../services/optimazeHome';
import { DomSanitizer } from '@angular/platform-browser';
import {VideoModalComponent} from '../../../shared/video-modal/video-modal.component';
declare var jQuery: any;

@Component({
  selector: 'app-home-first',
  templateUrl: './home-first.component.html',
  styleUrls: ['./home-first.component.scss'],
  animations: [
    FADE_IN_OUT,
    BULB
  ]
})

export class HomeFirstComponent implements OnInit , OnDestroy, AfterViewInit  {
  readonly TEXTS = TEXTS;
  modalIsShown = false;
  isMobile: boolean;
  videoHtml;

  @ViewChild('videoStarts') videoStarts: ElementRef;
  @ViewChild('openVideoFull') openVideoFull: VideoModalComponent;

  constructor(private coefService: CoefService , private optimazeHome: OptimazeHome , private domSanitizer: DomSanitizer ) {
    this.isMobile = this.coefService.getDeviceType();
  }
  ngAfterViewInit () {
    if (!this.isMobile) {
      let video = this.videoStarts.nativeElement;
      this.optimazeHome.stopVideo(video , -1 , 1200 , true);
    }
  }
  iphone = false;
  ngOnInit() {
    if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
      this.iphone = true;
    }
    setTimeout(() => {
      jQuery('#videoSrc3').attr('src', 'https://player.vimeo.com/external/261800071.hd.mp4?s=9bd64ef8650bf76aa563984140c21ca20a393080&profile_id=174');
    }, 100);
    this.videoHtml =  this.coefService.videoHtml('', 'videoSrc3', `poster="/assets/img/sefiPoster/mobile-6-2-video-floor-1-without-effect_1.jpg"` );
  }
  toggleModal() {
    this.modalIsShown = !this.modalIsShown;
    if (this.modalIsShown === true) {
      // document.documentElement.style.overflow = 'hidden';
    } else {
      // document.documentElement.style.overflowY = 'scroll';
      // this.openVideoFull.openVideo();
    }
  }
  ngOnDestroy() {
    this.optimazeHome.unSuscribe();
  }
}
