<div (click)="goToFloat()" class="home-float">
  <div class="image section-bg">
    <img src="/assets/img/home/float/home-float-bg.png" alt="">
    <img class="mobile" src="/assets/img/home/float/home-float-bg-mobile.png" alt="">
  </div>
  <div class="image-abs twitter">
    <img src="/assets/img/home/float/home-float-twitter-min.png" alt="">
  </div>
  <div class="image-abs instagram">
    <img src="/assets/img/home/float/home-float-instagram-min.png" alt="">
  </div>
  <div class="image-abs facebook">
    <img src="/assets/img/home/float/home-float-facebook-min.png" alt="">
  </div>
  <div class="image-abs linkedin">
    <img src="/assets/img/home/float/home-float-linkedin-min.png" alt="">
  </div>
  <div class="image-abs google">
    <img src="/assets/img/home/float/home-float-google-min.png" alt="">
  </div>
  <div class="image-abs pinterest">
    <img src="/assets/img/home/float/home-float-pinterest-min.png" alt="">
  </div>
  <div class="home-float-inner">
    <div class="text text-mobile">
      <div class="float-tags">
        <p>#מיתוג / #מובייל / #UI# / UX / #מיתוג / #פוסט פרודקשיין / #אנימציה</p>
      </div>
      <div class="float-button">
        <div class="a">מה היה לנו?</div>
      </div>
    </div>
    <div class="image">
      <img src="/assets/img/home/float/home-float-image.png" alt="">
    </div>
    <div class="text">
      <div class="text-inner">
        <div class="float-logo">
          <img src="/assets/img/float/float_logo_bold.svg" alt="">
        </div>
        <div class="float-desc">
          <p>״הסיפור של האנשים שמאחורי תעשיית הדיגיטל״<br>
            איך הופכים מוצר דאטא להרפתקאת<br>
            נשיונאל-ג׳אוגראפיק מתחת למים?<br>
            קחו אוויר ותהנו… </p>
        </div>
        <div class="float-tags">
          <p>#מיתוג / #מובייל / #UI# / UX / #פוסט פרודקשיין / #אנימציה</p>
        </div>
        <div class="float-button">
          <div class="a">מה היה לנו?</div>
        </div>
      </div>
    </div>
  </div>
</div>