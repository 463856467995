<!--<img class="particle" src="/assets/img/home/background_huggies.png" alt="">-->
<video poster="/assets/img/home/Poster4.jpg" *ngIf="!isMobile"  id="video_huggies" [src]="isMobile ? 'https://player.vimeo.com/external/258416450.hd.mp4?s=c38a78a7d2292940c32b55fe6a09e9d691dbdffd&profile_id=174' : 'https://player.vimeo.com/external/258416450.hd.mp4?s=c38a78a7d2292940c32b55fe6a09e9d691dbdffd&profile_id=174'" src="" playsinline loop muted autoplay #videoPlayer></video>
<div *ngIf="isMobile" [innerHtml]="videoHtml"></div>
<div  (click)="goHuggies()" style="cursor: pointer" class="huggies">
  <div class="wraper_container">
    <div class="col1">
      <div class="wraper_info_huggies">
        <img  [src]="isMobile ? '/assets/img/home/Mobile/logo-huggis.png' : '/assets/img/home/logo-huggis.png'" src="" alt="" >
        <p class="description" > {{TEXT.textHuggiesHome}}</p>
        <p class="hashtag hashtagHome"  [innerText]="!isMobile ? TEXT.hashtag : TEXT.hashtag2" ></p>
        <div class="wrapper_btn">
          <a class="btn styleBtnHome" (click)="goHuggies()">{{TEXT.btnWalla}}</a></div>
      </div>
    </div>
  </div>
</div>
