import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {PAGE, PAGE_PATH} from './router.path';
import {trigger} from '@angular/animations';


const APP_ROUTES: Routes = [
  {path: PAGE.HOME, component: HomeComponent, pathMatch: 'full'},
  { path: PAGE.TEMPO_PAGE, loadChildren: PAGE_PATH.TEMPO  },
  { path: PAGE.TANKA, loadChildren: PAGE_PATH.TANKA  },
  { path: PAGE.FIAT_PAGE, loadChildren: PAGE_PATH.FIAT  },
  { path: PAGE.WALLA_PAGE, loadChildren: PAGE_PATH.WALLA },
  { path: PAGE.YUVAL_PAGE, loadChildren: PAGE_PATH.YUVAL  },
  { path: PAGE.HUGGIES_PAGE, loadChildren: PAGE_PATH.HUGGIES },
  { path: PAGE.SAMSUNG_PAGE, loadChildren: PAGE_PATH.SAMSUNG  },
  { path: PAGE.CONTACT_PAGE, loadChildren: PAGE_PATH.CONTACT },
  { path: PAGE.ABOUT_PAGE, loadChildren: PAGE_PATH.ABOUT },
  {path: PAGE.FLOAT, loadChildren: PAGE_PATH.FLOAT},
  {path: '**', component: NotFoundComponent},
];

export const APP_ROUTES_CONST = RouterModule.forRoot(APP_ROUTES , {useHash: false});
