<!--<img [src]="!isMobile ? '/assets/img/home/background_logos_section.png' : ''" alt="" class="main_background">-->

<div class="main_block">
  <div class="logos_block centered" >
    <div class="element">
      <img src="/assets/img/home/brand_logos/logo_samsung.png" class="white">
      <img src="/assets/img/home/brand_logos/logo_samsung_colored.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/logo_microsoft.png" class="white">
      <img src="/assets/img/home/brand_logos/logo_microsoft_colored.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/logo_walla.png" class="white">
      <img src="/assets/img/home/brand_logos/logo_walla_colored.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/logo_coca_cola.png" class="white">
      <img src="/assets/img/home/brand_logos/logo_coca_cola_colored.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/logo_jerusalem_post.png" class="white">
      <img src="/assets/img/home/brand_logos/logo_jerusalem_post_colored.png" class="colored">
    </div>
      <div *ngIf="!isMobile" class="element">
        <img src="/assets/img/home/brand_logos/logo_ibiic.png" class="white">
        <img src="/assets/img/home/brand_logos/logo_ibiic_colored.png" class="colored">
      </div>
      <div *ngIf="!isMobile" class="element">
        <img src="/assets/img/home/brand_logos/logo_pepsi.png" class="white">
        <img src="/assets/img/home/brand_logos/logo_pepsi_colored.png" class="colored">
      </div>
      <div *ngIf="!isMobile" class="element">
        <img src="/assets/img/home/brand_logos/logo_leumi.png" class="white">
        <img src="/assets/img/home/brand_logos/logo_leumi_colored.png" class="colored">
      </div>
  </div>
  <div class="logos_block logos_block2 centered" *ngIf="moreLogosAreShown  " @easeInOut>
    <div class="element">
      <img src="/assets/img/home/brand_logos/Huggis-white.png" class="white">
      <img src="/assets/img/home/brand_logos/Huggis-color.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/el-al-white.png" class="white">
      <img src="/assets/img/home/brand_logos/el-al-color.png" class="colored">
    </div>
    <div class="element" *ngIf="isMobile" style="padding: 0% 23% 0% 23%">
      <img src="/assets/img/home/brand_logos/fiat-white.png" class="white">
      <img src="/assets/img/home/brand_logos/fiat-color.png" style="padding: 0% 15% 0% 15%" class="colored">
    </div>
    <div class="element" *ngIf="!isMobile" >
      <img src="/assets/img/home/brand_logos/fiat-white.png" class="white width_img">
      <img src="/assets/img/home/brand_logos/fiat-color.png"   class="colored width_img">
    </div>
    <div *ngIf="isMobile" class="element" style="padding: 0% 23% 0% 23%">
      <img src="/assets/img/home/brand_logos/bmw-white.png" class="white">
      <img src="/assets/img/home/brand_logos/bmw-color.png" style="padding: 0% 15% 0% 15%" class="colored">
    </div>
    <div *ngIf="!isMobile" class="element" >
      <img src="/assets/img/home/brand_logos/bmw-white.png" class="white width_img">
      <img src="/assets/img/home/brand_logos/bmw-color.png"  class="colored width_img">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/similac-white.png" class="white">
      <img src="/assets/img/home/brand_logos/similac-color.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/subaru-white.png" class="white">
      <img src="/assets/img/home/brand_logos/subaru-color.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/sport-1-white.png" class="white">
      <img src="/assets/img/home/brand_logos/sport-1-color.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/new-balance-white.png" class="white">
      <img src="/assets/img/home/brand_logos/new-balance-color.png" class="colored">
    </div>
  </div>
  <div class="logos_block logos_block2 centered" *ngIf="moreLogosAreShown2" @easeInOut>

    <div class="element">
      <img src="/assets/img/home/brand_logos/maariv-white.png" class="white">
      <img src="/assets/img/home/brand_logos/maariv-color.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/arison-white.png" class="white">
      <img src="/assets/img/home/brand_logos/arison-color.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/tadiran-white.png" class="white">
      <img src="/assets/img/home/brand_logos/tadiran-color.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/fattal-white.png" class="white">
      <img src="/assets/img/home/brand_logos/fattal-color.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/bezeq-white.png" class="white">
      <img src="/assets/img/home/brand_logos/bezeq-color.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/electra-white.png" class="white">
      <img src="/assets/img/home/brand_logos/electra-color.png" class="colored">
    </div>
    <div *ngIf="!isMobile" class="element">
      <img src="/assets/img/home/brand_logos/yad-2-white.png" class="white">
      <img src="/assets/img/home/brand_logos/yad-2-color.png" class="colored">
    </div>
    <div *ngIf="isMobile" class="element" style="padding: 0% 11%;">
      <img src="/assets/img/home/brand_logos/yad-2-white.png" class="white">
      <img src="/assets/img/home/brand_logos/yad-2-color.png" class="colored">
    </div>
    <div class="element">
      <img src="/assets/img/home/brand_logos/xtremer-white.png" class="white">
      <img src="/assets/img/home/brand_logos/xtremer-color.png" class="colored">
    </div>
  </div>


  <button  class="loadmore centered" [ngClass]="{'color':moreLogosAreShown2}" (click)="toogleMoreLogosVisibility()" @easeInOut>
    <span class="btn_after_logo"  *ngIf="!moreLogosAreShown2">{{TEXTS.loadmore}}</span>
    <span class="btn_after_logo" *ngIf="moreLogosAreShown2"> {{TEXTS.loadmore2}}</span>
  </button>
  <!--<button class="loadmore centered" style="background: #ff0077" *ngIf="moreLogosAreShown2"  @easeInOut>-->
    <!--{{TEXTS.loadmore2}}-->
  <!--</button>-->

  <p class="note_text" [innerText]="TEXTS.homeNote.firstLine"></p>
  <p [innerText]="TEXTS.homeNote.mainText" class="note_text"></p>
  <!--<p class="test">vasa </p>-->

  <div style="position: relative">
    <div *ngIf="isMobile" class="mobile_triangle">
      <img class="Ellipse3" src="/assets/img/home/Mobile/Ellipse3.png" alt="">
      <img class="Polygon1" src="/assets/img/home/Mobile/Polygon1.png" alt="">
    </div>
    <span class="single_piece firstLine" [innerHtml]="TEXTS.homeNote.firstLine"></span>
    <span class="single_piece mainText" [innerText]="TEXTS.homeNote.mainText"></span>
    <span [innerHtml]="TEXTS.homeNote.firstPiece" class="single_piece first_piece"></span>
    <span [innerHtml]="TEXTS.homeNote.secondPiece" class="single_piece second_piece"></span>
    <span [innerHtml]="TEXTS.homeNote.thirdPiece" class="single_piece third_piece"></span>
    <span [innerHtml]="TEXTS.homeNote.fourthPiece" class="single_piece four_piece"></span>
    <span [innerHtml]="TEXTS.homeNote.fifthPiece" class="single_piece five_piece"></span>
    <span [innerHtml]="TEXTS.homeNote.sixthPiece" class="single_piece six_piece"></span>
    <span [innerHtml]="TEXTS.homeNote.seventhPiece" class="single_piece seven_piece"></span>
    <span [innerHtml]="TEXTS.homeNote.eightPiece" class="single_piece eight_piece"></span>
    <span [innerHtml]="TEXTS.homeNote.ninethPiece" class="single_piece night_piece"></span>
    <span [innerHtml]="TEXTS.homeNote.tenhPiece" class="single_piece ten_piece"></span>
  </div>
  <img
    [src]="!isMobile ? '/assets/img/home/page_hand.png' : '/assets/img/home/Mobile/page_hand.png'"
    class="page_hand" alt="">
</div>
