import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PageService} from "../../services/page.service";
import {ScrollUpDownService} from "../../services/scrollUpDown.service";
import {ScrollService} from "../../services/scroll.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import "rxjs/add/operator/filter";
import {TEXTS} from "../../common/texts";
import {BULB, FADE_IN_OUT, PORTFOLIO_FADE_OUT} from "../../common/animations";
import {CoefService} from "../../services/coef.service";

declare var jQuery: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [
    PORTFOLIO_FADE_OUT
  ]
})
export class HeaderComponent implements OnInit ,  AfterViewInit{
  padding = "0%";
  readonly TEXT = TEXTS;
  modal_play_video = true;
  isMobile: boolean;

  constructor(private route: Router, private pageService: PageService, private scrollUpDownService: ScrollUpDownService, private coefService: CoefService, private scrollService: ScrollService) {
    this.isMobile = this.coefService.getDeviceType();
    // console.log( this.coefService.getDeviceType());
    route.events.filter(event => event instanceof NavigationEnd)
      .subscribe(event => {
        if (event["url"] === "/fiat") {
          this.padding = "3%";
        } else {
          this.padding = "0%";
        }
      });

  }

  ngAfterViewInit() {
    window.scrollTo(0, 1);
  }
  ngOnInit() {

    sessionStorage.getItem("modal_event");
    if (sessionStorage.getItem("modal_event") !== "close") {
      sessionStorage.setItem("modal_event", "open");
    } else {
      this.modal_play_video = false;
    }
  }


  close_modal_play_video() {
    this.modal_play_video = false;
    sessionStorage.setItem("modal_event", "close");
    window.scrollTo(0, 1);
  }

  goHome() {
    this.scrollService.animationTOpDowan = false;
    this.pageService.goHome();
  }

  goContact() {
    this.scrollService.animationTOpDowan = false;
    this.pageService.goContact();
  }

  goAbout() {
    this.scrollService.animationTOpDowan = false;
    this.pageService.goAbout();
  }

  goWalla() {
    if (jQuery("#toThisElement").length) {
      jQuery("html, body").animate({
        scrollTop: jQuery("#toThisElement").offset().top
      }, 2000);
    }
    // document.querySelector('#toThisElement .wrapper_btn'  ).scrollIntoView({block: "end", behavior: "smooth"});
    this.scrollService.animationTOpDowan = false;
    this.scrollUpDownService.homePageProjectScroll = true;
    localStorage.setItem("homePageProjectScroll", "true");
    this.pageService.goHome();
  }
}
