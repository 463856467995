import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MyWayComponent} from './my-way/my-way.component';
import {ShareMenuComponent} from './share-menu/share-menu.component';
import {MainMenuComponent} from './main-menu/main-menu.component';
import { FooterPortfolioComponent } from './footer-portfolio/footer-portfolio.component';
import {FooterFloatComponent} from "./footer-float/footer-float.component";
import { FadeInAnimationComponent } from './portfolio-fade-in/fade-in-animation/fade-in-animation.component';
import { VideoModalComponent } from './video-modal/video-modal.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule

  ],
  declarations: [
    ShareMenuComponent,
    MyWayComponent,
    MainMenuComponent,
    FooterPortfolioComponent,
    FooterFloatComponent,
    FadeInAnimationComponent,
    VideoModalComponent
  ],
  exports: [
    MyWayComponent,
    ShareMenuComponent,
    MainMenuComponent,
    FooterPortfolioComponent,
    FooterFloatComponent,
    FadeInAnimationComponent,
    VideoModalComponent
  ]
})
export class SharedModule {
}
