import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {TEXTS} from '../../../common/texts';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs/Subscription';
import {CoefService} from '../../../services/coef.service';
import {ScrollService} from '../../../services/scroll.service';
import {PageService} from '../../../services/page.service';

@Component({
  selector: 'app-home-seventh',
  templateUrl: './home-seventh.component.html',
  styleUrls: ['./home-seventh.component.scss'],
  animations: [
    trigger('scrolledState', [
      state('hidden', style({
        fontSize: '20.9rem',
        fontFamily: 'kozuka-gothic-pro',
        fontWeight: 900,
        textTransform: 'uppercase',
        color: 'rgba(255, 255, 255, 0.09)',
        lineHeight: 1,
        textAlign: 'left',
        position: 'absolute',
        bottom: '16%',
        right: '-56%'
      })),
      state('shown', style({
        fontSize: '20.9rem',
        fontFamily: 'kozuka-gothic-pro',
        fontWeight: 900,
        textTransform: 'uppercase',
        color: 'rgba(255, 255, 255, 0.09)',
        lineHeight: 1,
        textAlign: 'left',
        position: 'absolute',
        bottom: '16%',
        right: '-15%'
      })),
      transition('hidden => shown', animate('1.5s ease-out'))
    ])
  ]
})
export class HomeSeventhComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly TEXT = TEXTS;
  animationState = 'hidden';

  isMobile: boolean;

  private streams$: Subscription[] = [];

  private scrollPosition: number;
  private coef: number;

  constructor(private coefService: CoefService,
              private scrollService: ScrollService,
              private pageService: PageService) {
    this.isMobile = this.coefService.getDeviceType();
  }

  goYuval() {
    this.pageService.goYuval();
  }

  ngOnInit() {
    this.coef = this.coefService.getCoef();
    this.streams$.push(this.coefService.coefHasChanged.subscribe(
      (newCoef: number) => {
        this.coef = newCoef;
      }
    ));

    this.streams$.push(this.scrollService.scrollPositionHasChanged.subscribe(
      (newScrollPosition: number) => {
        this.scrollPosition = newScrollPosition;
        if (this.scrollPosition > 6900 * this.coef) {
          this.animationState = 'shown';
        }
      }
    ));
  }

  ngAfterViewInit() {
    this.scrollService.getScrollPosition().then(e => {
      this.scrollPosition = +e;

      if (this.scrollPosition > 6900 * this.coef) {
        this.animationState = 'shown';
      }
    });
  }

  ngOnDestroy() {
    for (const stream$ of this.streams$) {
      stream$.unsubscribe();
    }
  }


}
