import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {INFO_PROJECT_HOME, TEXTS} from '../../common/texts';
import {CoefService} from '../../services/coef.service';
import {PageService} from '../../services/page.service';
import {OptimazeHome} from '../../services/optimazeHome';
import {DomSanitizer} from '@angular/platform-browser';
declare var jQuery:any;
@Component({
  selector: 'app-my-way',
  templateUrl: './my-way.component.html',
  styleUrls: ['./my-way.component.scss']
})
export class MyWayComponent implements OnInit , OnDestroy, AfterViewInit {
  isMobile: boolean;
  readonly TEXT = TEXTS;
  videoHtml;
  @ViewChild('videoPlayer') videoPlayer: any;

  constructor(private coefService: CoefService , private pageService: PageService , private optimazeHome: OptimazeHome , private domSanitizer: DomSanitizer ){
    this.isMobile = this.coefService.getDeviceType();
  }

  ngOnInit() {
    setTimeout(() => {
      jQuery('#videoSrc2').attr('src', 'https://player.vimeo.com/external/258416871.sd.mp4?s=e95db7279457f2d41cd57e921cada940f1f368f1&profile_id=165');
    }, 100);
    this.videoHtml = this.coefService.videoHtml('', 'videoSrc2', `poster="/assets/img/home/Screenshot_4.png"` );
  }
  rout() {
    this.pageService.goAbout();
  }
  wallaRout(){
    this.pageService.goWalla();
  }
  ngAfterViewInit() {
  if (!this.isMobile) {
    let video = this.videoPlayer.nativeElement;
    this.optimazeHome.stopVideo(video , 2500 , 4000);
  }
  }
  ngOnDestroy() {
    this.optimazeHome.unSuscribe();
  }
}
