<section class="home_four">
  <video poster="/assets/img/home/Poster3.jpg" *ngIf="!isMobile" #videoPlayer playsinline loop muted autoplay [src]="isMobile ? 'https://player.vimeo.com/external/258416429.hd.mp4?s=5e292d58de22d0ef346686e7634e56f1cf25788f&profile_id=175' : 'https://player.vimeo.com/external/258416429.hd.mp4?s=5e292d58de22d0ef346686e7634e56f1cf25788f&profile_id=175'">
  </video>
  <div *ngIf="isMobile" [innerHtml]="videoHtml"></div>
  <div class="wrapper_text_floor">
    <p class="title" [innerText]="TEXT.title_sefi_home_floor"></p>
    <p *ngIf="!isMobile" class="description" >
      הצלחתי, נפלתי, התקדמתי אבל תמיד-תמיד
      <br>
      שמרתי על העקרונות ותהליך היצירה
      <br>
      במילה אחת - מוזה.

    </p>
    <!--<p *ngIf="isMobile"  class="description" [innerText]="TEXT.description_sefi_home_floorMob"></p>-->
    <p *ngIf="isMobile"  class="description" >
      למדתי, הצלחתי, נפלתי, התקדמתי אבל
      <br>
      תמיד-תמיד שמרתי על העקרונות ותהליך היצירה.

    </p>
    <a class="btn" (click)="rout()" [innerText]="TEXT.btnTextFloorHome"></a>
  </div>
</section>
