import {Component, OnInit} from '@angular/core';
import {TEXTS} from '../../../common/texts';
import {PageService} from '../../../services/page.service';
import {CoefService} from '../../../services/coef.service';

@Component({
  selector: 'app-home-ten',
  templateUrl: './home-ten.component.html',
  styleUrls: ['./home-ten.component.scss']
})
export class HomeTenComponent implements OnInit {
  readonly TEXT = TEXTS;
  isMobile: boolean;

  constructor(private pageService: PageService, private coefService: CoefService) {
    this.isMobile = this.coefService.getDeviceType();
  }

  goSamsung() {
    this.pageService.goSamsung();
  }

  ngOnInit() {
  }

}
