import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CoefService} from '../../services/coef.service';

declare var jQuery: any;

@Component({
    selector: 'app-video-modal',
    templateUrl: './video-modal.component.html',
    styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit, AfterViewInit {
    @Output() modalClosed = new EventEmitter<any>();

    @Input() videoSrc;
    isMobile;

    constructor(private coefService: CoefService) {
        this.isMobile = this.coefService.getDeviceType();
    }

    iphone = false;

    ngAfterViewInit() {

    }

    ngOnInit() {
        if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
            this.iphone = true;
            jQuery('.close').css({
                display: 'none',
            });
            var vid = document.getElementById('openVideoFull');
            vid.addEventListener('webkitendfullscreen', function(e) {
                this.onModalClosed();
            });
        } else {
            if (this.isMobile) {
                this.iphone = true;
                jQuery('app-video-modal').css({
                    left: '0px',
                    background: 'rgba(0,0,0,1)'
                });
                var vid = document.getElementById('openVideoFull');
                this.open(vid);
                // jQuery(window).bind("resize", function (e) {
                //   console.log('sad')
                // })
            }
        }
        if (this.isMobile) {
            var vid = document.getElementById('openVideoFull');
            vid.addEventListener('webkitendfullscreen', (e) => {
                console.log('full screen ended');
                this.onModalClosed();
            });
            vid.addEventListener('webkitenterfullscreen', (e) => {
                // alert('test2')
            });
            // jQuery(document).on("click",() => {
            //   var player = jQuery('#openVideoFull');
            //   console.log('isFullscreen');
            //   if (!player.isFullscreen()) {
            //     alert('Exited fullscreen');
            //   }
            // });
        }
    }

    open(elem) {
        elem.play();
        // if (typeof(elem.webkitEnterFullscreen) != 'undefined') {
        //   // This is for Android Stock.
        //   elem.webkitEnterFullscreen();
        // } else if (typeof(elem.webkitRequestFullscreen) != 'undefined') {
        //   // This is for Chrome.
        //   elem.webkitRequestFullscreen();
        // } else if (typeof(elem.mozRequestFullScreen) != 'undefined') {
        //   elem.mozRequestFullScreen();
        // }
        // elem.webkitEnterFullScreen();
        elem.play();

    }

    onModalClosed() {
        this.modalClosed.emit();
    }
}

