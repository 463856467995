import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {PORTFOLIO_FADE_OUT} from '../../../common/animations';
import {ScrollService} from '../../../services/scroll.service';
import {CoefService} from '../../../services/coef.service';
import {TEXTS} from '../../../common/texts';

declare var jQuery: any;

@Component({
  selector: 'app-fade-in-animation',
  templateUrl: './fade-in-animation.component.html',
  styleUrls: ['./fade-in-animation.component.scss'],
  animations: [PORTFOLIO_FADE_OUT]
})
export class FadeInAnimationComponent implements OnInit, AfterViewInit {
  @Input() color;
  @Input() colorUp;
  @Input() reload = true;
  @Input() portfolio = true;
  readonly TEXTS = TEXTS;
  blockIsShown = true;
  show = true;
  animationTOpDowan;
  isMobile = false;
  start = false;
  constructor(private scrollService: ScrollService , private coefService: CoefService) {
 setTimeout(() => {
   this.start = true;
 }, 1000);
    this.isMobile = this.coefService.getDeviceType();
    this.animationTOpDowan = this.scrollService.animationTOpDowan;
    if (this.scrollService.animationTOpDowan) {
      // this.blockIsShown = false ;
      this.color = this.colorUp;
      // console.log(this.color);
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.isMobile) {
      if (this.reload) {
        window.addEventListener('load', () => {
          setTimeout(() => {
            this.blockIsShown = false;
          }, 320);
        });
      }else {
        setTimeout(() => {
          this.blockIsShown = false;
        }, 1250);
      }
    }else {
      setTimeout(() => {
        this.blockIsShown = false;
      }, 2250);
    }

  }

}
