import {Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {INFO_PROJECT_HOME, TEXTS} from '../../../common/texts';
import {CoefService} from '../../../services/coef.service';
import {Subscription} from 'rxjs/Subscription';
import {PageService} from '../../../services/page.service';
import set = Reflect.set;
import {OptimazeHome} from '../../../services/optimazeHome';
import {DomSanitizer} from '@angular/platform-browser';
import {ScrollService} from '../../../services/scroll.service';

declare var jQuery: any;

@Component({
  selector: 'app-home-second',
  templateUrl: './home-second.component.html',
  styleUrls: ['./home-second.component.scss'],

})
export class HomeSecondComponent implements OnInit, OnDestroy, AfterViewInit {
  private streams$: Subscription[] = [];
  coef;
  subscription;

  private coefSubscription: Subscription;
  private videoHasStarted = true;
  readonly TEXT2 = INFO_PROJECT_HOME;
  readonly TEXT = TEXTS;
  private videos;

  isMobile: boolean;

  @ViewChild('video') video: ElementRef;
  @ViewChild('info') info: ElementRef;
  @ViewChild('section') section: ElementRef;
  @ViewChild('videoStarts') videoStarts: ElementRef;

  @ViewChild('videoStartsFirst') videoStartsFirst: ElementRef;
  // videos = [
  //   'http://test.betedo.com/videos/Desktop/Home/video-tablet-samsung-joni_1.mp4',
  //   'http://test.betedo.com/videos/Desktop/Home/video-tablet-tempo-joni_1.mp4',
  //   'http://test.betedo.com/videos/Desktop/Home/video-tablet-walla-joni_1.mp4',
  //   'http://test.betedo.com/videos/Desktop/Home/video-tablet-fiat-joni_1.mp4',
  //   'http://test.betedo.com/videos/Desktop/Home/video-tablet-huggis-joni_1.mp4'
  // ];

  // videos = [
  //   'http://test.betedo.com/videos/Mobile/Home/mobile-6-2-video-floor-2-joni-samsung_1.mp4',
  //   'http://test.betedo.com/videos/Mobile/Home/mobile-6-2-video-floor-2-joni-tempo_1_1.mp4',
  //   'http://test.betedo.com/videos/Mobile/Home/mobile-6-2-video-floor-2-joni-walla_2_1.mp4',
  //   'http://test.betedo.com/videos/Mobile/Home/mobile-6-2-video-floor-2-joni-fiat_2_1.mp4',
  //   'http://test.betedo.com/videos/Mobile/Home/mobile-6-2-video-floor-2-joni-huggis_1_1.mp4'
  // ];
  goWalla() {
    this.pageService.goWalla();
  }


  logo_progects = [
    '/assets/img/home/samsung.png',
    '/assets/img/home/tempo.png',
    '/assets/img/home/walla.png',
    '/assets/img/home/fiat.png',
    '/assets/img/home/huggies.png',
  ];
  logo_progectsObject = [
    {src: '/assets/img/home/samsung.png', style: '100%'},
    {src: '/assets/img/home/tempo.png', style: '80%'},
    {src: '/assets/img/home/walla.png', style: '80%'},
    {src: '/assets/img/home/fiat.png', style: '40%'},
    {src: '/assets/img/home/huggies.png', style: '75%'},

  ];
  logo_progectsObjectMob = [
    {src: '/assets/img/home/samsung.png', style: '75%'},
    {src: '/assets/img/home/tempo.png', style: '70%'},
    {src: '/assets/img/home/walla.png', style: '70%'},
    {src: '/assets/img/home/fiat.png', style: '38%'},
    {src: '/assets/img/home/huggies.png', style: '70%'},
  ];
  logo_progectsMob = [
    '/assets/img/home/samsung.png',
    '/assets/img/home/tempo.png',
    '/assets/img/home/walla.png',
    '/assets/img/home/fiatTest.png',
    '/assets/img/home/huggies.png',
  ];
  link_progects = [
    'https://www.google.com.ua',
    'https://www.google.com.ua1',
    'https://www.google.com.ua2',
    'https://www.google.com.ua3',
    'https://www.google.com.ua4',
  ];
  timingArray = [3800, 4500, 3800, 4200, 4000];
  currentVideoIndex = 0;
  current_logo_src = this.logo_progects[this.currentVideoIndex];
  current_link_src = this.link_progects[this.currentVideoIndex];
  currentVideo;

  constructor(private coefService: CoefService,
              private pageService: PageService,
              private optimazeHome: OptimazeHome,
              private domSanitizer: DomSanitizer,
              private scrollService: ScrollService) {
    this.isMobile = this.coefService.getDeviceType();
    if (this.isMobile) {
      this.logo_progectsObject[0]['style'] = '75%';
      this.logo_progectsObject[1]['style'] = '70%';
      this.logo_progectsObject[2]['style'] = '70%';
      this.logo_progectsObject[3]['style'] = '38%';
      this.logo_progectsObject[4]['style'] = '70%';
    }
  }

  videoCurenTime;
  current_logo_src_full = this.logo_progectsObject[0]['src'];
  styleWidth;

  ngAfterViewInit() {
    this.playVideo();
    this.videoCurenTime = document.getElementById('videoLoad');
    jQuery('#videoLoad').on('play', () => {
      this.info.nativeElement.style.opacity = 1;
      setInterval(() => {
        if (this.isMobile) {
          this.changeSourcesLogo(19.5, 19.8, this.logo_progectsObjectMob[0]['src'], 0, 0.2, this.logo_progectsObjectMob[0]['style']);
          this.changeSourcesLogo(3.5, 3.8, this.logo_progectsObjectMob[1]['src'], 4, 4.2, this.logo_progectsObjectMob[1]['style']);
          this.changeSourcesLogo(7.5, 7.8, this.logo_progectsObjectMob[2]['src'], 8, 8.2, this.logo_progectsObjectMob[2]['style']);
          this.changeSourcesLogo(11.5, 11.8, this.logo_progectsObjectMob[3]['src'], 12, 12.2, this.logo_progectsObjectMob[3]['style']);
          this.changeSourcesLogo(15.5, 15.8, this.logo_progectsObjectMob[4]['src'], 16, 16.2, this.logo_progectsObjectMob[4]['style']);
        } else {
          this.changeSourcesLogo(19.5, 19.8, this.logo_progectsObject[0]['src'], 0, 0.2, this.logo_progectsObject[0]['style']);
          this.changeSourcesLogo(3.5, 3.8, this.logo_progectsObject[1]['src'], 4, 4.2, this.logo_progectsObject[1]['style']);
          this.changeSourcesLogo(7.5, 7.8, this.logo_progectsObject[2]['src'], 8, 8.2, this.logo_progectsObject[2]['style']);
          this.changeSourcesLogo(11.5, 11.8, this.logo_progectsObject[3]['src'], 12, 12.2, this.logo_progectsObject[3]['style']);
          this.changeSourcesLogo(15.5, 15.8, this.logo_progectsObject[4]['src'], 16, 16.2, this.logo_progectsObject[4]['style']);
        }
      }, 100);
    });
  }

  changeSourcesLogo(range1, range2, logo_progects, range3, range4, style) {
    if (this.videoCurenTime.currentTime > range1 && this.videoCurenTime.currentTime < range2) {
      this.info.nativeElement.style.opacity = 0;
    }
    if (this.videoCurenTime.currentTime > range3 - 0.1 && this.videoCurenTime.currentTime < range4 - 0.1) {
      this.styleWidth = style;
      this.current_logo_src_full = '';
    }
    if (this.videoCurenTime.currentTime > range3 && this.videoCurenTime.currentTime < range4) {
      this.info.nativeElement.style.opacity = 1;
      this.current_logo_src_full = '';
      this.current_logo_src_full = logo_progects;
      this.styleWidth = style;
      // console.log(this.styleWidth);
    }
  }

  videoHtml;

  ngOnInit() {
    this.coef = this.coefService.getCoef();
    this.subscription = this.coefService.coefHasChanged.subscribe(
      (newCoef: number) => {
        this.coef = newCoef;
      });

    this.logo_progects[3] = '/assets/img/home/fiatTest.png';
    if (!this.isMobile) {
      this.logo_progects[0] = '/assets/img/home/samsungTest.png';
    }
    if (this.isMobile) {

      setTimeout(() => {
        jQuery('#videoLoad').attr('src', 'https://player.vimeo.com/external/259667669.sd.mp4?s=154a9465142f5407b7c769c833ce2db0f6a553d0&profile_id=165');
      }, 100);
      this.videoHtml = this.coefService.videoHtml('', 'videoLoad', `poster="/assets/img/home/seconPoster1.jpg"`);
    }
    // if(this.isMobile){
    //   setInterval(()=>{
    //     this.test();
    //   },4000)
    // }
    if (!this.isMobile) {
      this.videos = [
        'https://player.vimeo.com/external/258416436.hd.mp4?s=c51fa23019e426720cf6ffa521b49c0788071112&profile_id=175',
        'https://player.vimeo.com/external/258416440.hd.mp4?s=ef4e41036c9bc30db74fbd4714274ef05528e71e&profile_id=175',
        'https://player.vimeo.com/external/258416444.hd.mp4?s=cb43782afaf61277a5104efaeb7bd77ff3049241&profile_id=175',
        'https://player.vimeo.com/external/258416432.hd.mp4?s=b257d097cfe74a33cba2fcc3a5caf7ad1f1d96ef&profile_id=175',
        'https://player.vimeo.com/external/258942898.hd.mp4?s=12d09de7e4b5b635bec1f850555db8fad5e8e8b3&profile_id=175'
      ];
    } else {
      this.videos = [
        'https://player.vimeo.com/external/258417462.hd.mp4?s=8d91044f66a6419d74db12ef92e157fd746c47bf&profile_id=174',
        'https://player.vimeo.com/external/258417468.hd.mp4?s=730a17f4da8224a762f6695c17d74b8d7099248c&profile_id=174',
        'https://player.vimeo.com/external/258417475.hd.mp4?s=95bbfaa190127408ec3e77df376a9a16bc3249af&profile_id=174',
        'https://player.vimeo.com/external/258417446.hd.mp4?s=6be9117fb0d7ab129b80c8d2636af2c565d7e0fb&profile_id=174',
        'https://player.vimeo.com/external/258778345.hd.mp4?s=f1d284e3aaf3bea2e62a7b57948172b0c172d118&profile_id=174'
      ];
    }

    this.currentVideo = this.videos[this.currentVideoIndex];
    // window.addEventListener('resize', () => {
    //     this.video.nativeElement.style.height = 'auto';
    //     this.section.nativeElement.style.height = getComputedStyle(this.section.nativeElement).height;
    // });
    this.coefSubscription = this.coefService.coefHasChanged.subscribe(
      () => {
        this.section.nativeElement.style.height = getComputedStyle(this.video.nativeElement).height;
      }
    );
  }

  onVidStarted() {
    // console.log('start')
    // if(!this.isMobile){
    //   if (this.videoHasStarted) {
    //     this.videoHasStarted = false;
    //     this.video.nativeElement.style.opacity = 1;
    //     this.info.nativeElement.style.opacity = 1;
    //     const timeOut = (this.video.nativeElement.duration * 1000)-800;
    //     setTimeout(() => {
    //       this.video.nativeElement.style.opacity = 0;
    //       this.info.nativeElement.style.opacity = 0;
    //     }, timeOut);
    //     setTimeout(() => {
    //       this.videoHasStarted = true;
    //       this.section.nativeElement.style.height = getComputedStyle(this.section.nativeElement).height;
    //
    //       this.changeSources();
    //     }, this.video.nativeElement.duration * 1000 + 1500);
    //   }
    // }
  }


  changeSources() {
    this.currentVideoIndex += 1;
    if (this.currentVideoIndex > this.videos.length - 1) {
      this.currentVideoIndex = 0;
    }
    this.currentVideo = this.videos[this.currentVideoIndex];
    this.current_logo_src = this.logo_progects[this.currentVideoIndex];
    this.current_link_src = this.link_progects[this.currentVideoIndex];
  }


  // test(){
  //   this.currentVideoIndex += 1;
  //   if (this.currentVideoIndex > this.videos.length - 1) {
  //     this.currentVideoIndex = 0;
  //   }
  //   this.currentVideo = this.videos[this.currentVideoIndex];
  //   this.current_logo_src = this.logo_progectsMob[this.currentVideoIndex];
  //   this.current_link_src = this.link_progects[this.currentVideoIndex];
  //
  //   if(document.getElementById('testing')){
  //     document.getElementById('testing').remove();
  //   }
  //   setTimeout(()=> {
  //     var videoHtml = '<video class="video_repeater" poster="/assets/img/home/Mobile/backround_for_video-1px.png" id="video_loop" style="width: 100%"  playsinline loop muted autoplay src="'+ this.currentVideo +'"></video>';
  //     var wrapper =  document.getElementById('video_loop');
  //     if(wrapper){
  //       wrapper.outerHTML = videoHtml  ;
  //     }
  //   } , 500);
  // }

  playVideo() {
    let videoStartsFirst;
    if (!this.isMobile) {
      videoStartsFirst = this.videoStartsFirst.nativeElement;
      videoStartsFirst.pause();

    }
    this.coef = this.coefService.getCoef();
    this.streams$.push(this.coefService.coefHasChanged.subscribe(
      (newCoef: number) => {
        this.coef = newCoef;
      }
    ));


    this.streams$.push(this.scrollService.scrollPositionHasChanged.subscribe(
      (newScrollPosition: number) => {
        if (!this.isMobile) {
          if (1 * this.coef < newScrollPosition && 1000 * this.coef || 0 * this.coef < newScrollPosition && 1000 * this.coef > newScrollPosition) {
            videoStartsFirst.play();
          } else {
            videoStartsFirst.pause();
          }
        }
      }
    ));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    for (const stream$ of this.streams$) {
      stream$.unsubscribe();
    }
    this.optimazeHome.unSuscribe();
  }
}
